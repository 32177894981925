import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { IEnvironment } from './environment.interface';
import { loadVersion } from './load';

// These values are used across all environments except prod and testing

export const oktaClientIdTest = '0oa11o1vvwsBYP55W0h8';
export const oktaIssuerTest =
  'https://auth-test.sas.com/oauth2/ausy0okbbpyUOQqyd0h7';

// CEC_Global Module

// <script src="https://service-test.sas.com/scripts/sn_csm_ec.js?v=5.3"></script>
// <script>
// SN_CSM_EC.init({
//                 moduleID: "https://service-test.sas.com/#973624ac873fa1904f2543f5cebb35a5",
//                 loadFeature: SN_CSM_EC.loadEMFeature()
// });

// /* Call SN_CSM_EC.onLogin() function to start the user session in the Engagement Messenger automatically when the user logs in to the main portal */
// /* Call SN_CSM_EC.onLogout() function to terminate the user session in the Engagement Messenger automatically when the user logs out from the main portal */
// </script>

export const environmentDefaults: IEnvironment = {
  environmentName: 'default',
  debugi18n: false,
  graph: {
    endpoint: 'https://graph.microsoft.com/v1.0/me',
    clientId: 'd64d4ee9-c89d-4827-9308-8240ef118101',
    loginAuthority:
      'https://login.microsoftonline.com/b1c14d5c-3625-45b3-a430-9552373a0c2f',
    redirectUri: 'http://localhost:4200/',
  },
  csmChat: {
    scriptRef: 'https://service-test.sas.com/scripts/sn_csm_ec.js?v=5.3',
    globalModuleId:
      'https://service-test.sas.com/#973624ac873fa1904f2543f5cebb35a5',
    oktaScriptRef:
      'https://global.oktacdn.com/okta-auth-js/6.3.0/okta-auth-js.min.js',
    oktaIssuer: 'https://auth.sas.com/oauth2/ausahp1sb9nUNn5Cq2p7',
    oktaProfileClientId: '0oab5ffhjxC1JKPss2p7',
  },
  // by default, don't do production-related things
  production: false,
  version: loadVersion(),
  useRouteGuards: true,
  apiConnectorEndpoint: 'http://localhost:3333',
  chatUrl: 'https://brand-messenger.app.khoros.com/bundle/loader.js?v=',
  chatConfig: {
    companyKey: 'sasinstitutedev',
    appId: '6169815021d85d00df4014b5',
    widgetId: 'ae7064ee-993b-426f-a92e-7e1487e22a01',
  },
  // by not defining any defaults here it'll be obvious within the app
  // when an environment file is not properly set up (dev/prod need different
  // settings for auth)
  oidc: {
    clientId: '',
    issuer: '',
    redirectUri: '',
    scopes: [],
    pkce: true,
    testing: {
      disableHttpsCheck: false,
    },
  },
  sentryConfig: {
    dsn: 'https://fe746c8767e046949613faae254dbe04@sentry.mysas-k8s-dev.sas.com/2',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
  },
  gaTrackingCode: 'GTM-PWTVHH',
  links: {
    portalHelpLink: {
      url: 'https://go.documentation.sas.com/doc/{{language}}/mysasprtl/v_002/titlepage.htm',
    },
    community: {
      url: 'https://communities.sas.com',
    },
    askTheExpert: {
      url: 'https://www.sas.com/en_us/learn/ask-the-expert-webinars.geo.html',
    },
    viyaTrial: {
      url: 'https://www.sas.com/en_us/trials/software/viya/viya-trial-form.geo.html',
    },
    training: {
      url: 'https://www.sas.com/en_us/training/overview.geo.html',
    },
    orders: {
      url: '/orders',
    },
    csmMyCases: {
      url: 'https://sasdev.service-now.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
    },
    csmCreateCase: {
      url: 'https://sasdev.service-now.com/csm?id=sc_category&sys_id=1b8611d5c3921200b0449f2974d3ae12&catalog_id=-1&spa=1',
    },
  },
};
