import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { INotification } from '@mysas/shared/data-access-notifications';
import { NGXLogger } from 'ngx-logger';
import * as NotificationsActions from './notifications.actions';
import * as NotificationsSelectors from './notifications.selectors';

@Injectable()
export class NotificationsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.select(NotificationsSelectors.getNotificationsLoaded);
  allNotifications$ = this.store.select(
    NotificationsSelectors.getAllNotifications
  );
  unreadNotifications$ = this.store.select(
    NotificationsSelectors.getUnreadNotifications
  );

  logger = inject(NGXLogger);

  constructor(private readonly store: Store) {}

  addNotification(notification: INotification) {
    this.store.dispatch(NotificationsActions.addNotification({ notification }));
  }

  batchAddNotifications(notifications: INotification[]) {
    this.logger.debug(`Adding notifications!`, notifications);
    this.store.dispatch(
      NotificationsActions.batchAddNotifications({ notifications })
    );
  }

  dismissNotification(notification: INotification) {
    this.store.dispatch(
      NotificationsActions.dismissNotification({ notification })
    );
  }

  deleteNotification(slug: string) {
    this.store.dispatch(NotificationsActions.deleteNotification({ slug }));
  }

  deleteMultipleNotifications(slugs: string[]) {
    this.store.dispatch(
      NotificationsActions.deleteMultipleNotifications({ slugs })
    );
  }

  deleteAllNotifications() {
    this.store.dispatch(NotificationsActions.deleteAllNotifications());
  }
}
