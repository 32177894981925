import { ComponentType } from '@angular/cdk/portal';
import { ToastType } from '@mysas/portal/shared/data-access-toaster';
import {
  IOrder,
  IOrdersRequestAccess,
  SortByType,
  SortDirectionType,
} from '@mysas/shared/data-access-orders';
import { createAction, props } from '@ngrx/store';

export const initOrders = createAction('[Orders Page] Init');

export const loadOrdersSuccess = createAction(
  '[Orders/API] Load Orders Success',
  props<{ orders: IOrder[] }>()
);

export const loadOrdersFailure = createAction(
  '[Orders/API] Load Orders Failure',
  props<{ error: string }>()
);

export const changeSortBy = createAction(
  '[Orders Page] Sort By Changed',
  props<{ sortBy: SortByType }>()
);

export const toggleSortDirection = createAction(
  '[Orders List] Toggle Sort Direction'
);

export const changeSortDirection = createAction(
  '[Orders Page] Sort Direction Changed',
  props<{ sortDirection: SortDirectionType }>()
);

export const changeFilter = createAction(
  '[Orders Page] Filter Changed',
  props<{ filter: string | null }>()
);

/** Access Request Actions */

export const openAccessRequestDialog = createAction(
  '[Orders Page] Opened Access Request Dialog',
  props<{ component: ComponentType<unknown> }>()
);

export const closeAccessRequestDialog = createAction(
  '[Orders Page] Closed Access Request Dialog',
  props<{ toastType: ToastType; text: string }>()
);

export const submitAccessRequest = createAction(
  '[Orders Page] Requested Access',
  props<{ payload: IOrdersRequestAccess }>()
);

export const submitAccessRequestSuccess = createAction(
  '[Orders Page] Access Request Success',
  props<{ resp: unknown }>()
);

export const submitAccessRequestFailure = createAction(
  '[Orders Page] Access Request Failed',
  props<{ error: string }>()
);
