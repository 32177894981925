import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

export function looksLikeOrderNumber(order: any): boolean {
  return typeof order === 'string' && order.length == 6;
}

export function transformMyOrders(url: string): string {
  const params = new URL('http://localhost' + url).searchParams;
  let newUrl = 'orders';
  const orderValue = params.get('order');
  if (orderValue) {
    if (looksLikeOrderNumber(orderValue)) {
      newUrl += `/${orderValue}`;
    } else if (orderValue.length > 6 && orderValue.length <= 10) {
      newUrl += `?filter=${orderValue}`;
    }
  }
  return newUrl;
}

export function transformMyOrdersLang(url: string): string {
  let newUrl = 'orders';
  const params = new URL('http://localhost' + url).searchParams;
  const order = params.get('order');
  const tssite = params.get('tssite');
  if (order) {
    if (looksLikeOrderNumber(order)) {
      newUrl += `/${order}`;
    } else {
      newUrl += `?filter=${order}`;
    }
  } else if (tssite) {
    newUrl += `?filter=${tssite}`;
  }
  return newUrl;
}

export function findNewUrl(currentUrl: string): string {
  let newUrl = '';
  if (new RegExp('^/[a-zA-Z_-]{2,15}/my-orders.html').test(currentUrl)) {
    newUrl = transformMyOrders(currentUrl);
  } else if (
    new RegExp('^/[a-zA-Z_-]{2,15}/my-orders.lang.html').test(currentUrl)
  ) {
    newUrl = transformMyOrdersLang(currentUrl);
  } else if (new RegExp('^/[a-zA-Z_-]{2,15}/home.html').test(currentUrl)) {
    newUrl = '/';
  } else if (new RegExp('^/[a-zA-Z_-]{2,15}/home.lang.html').test(currentUrl)) {
    // https://my.sas.com/en/home.lang.html
    newUrl = '/';
  }
  return newUrl;
}

@Component({
  selector: 'mysas-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  private readonly logger = inject(NGXLogger);

  constructor(private router: Router) {}
  ngOnInit(): void {
    this.logger.info('ngOnInit');

    // Old links may be redirected here
    const newUrl = findNewUrl(this.router.url);
    if (newUrl != '') {
      this.router.navigateByUrl(newUrl);
    }
  }
}
