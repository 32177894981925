import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

export class ToastRef<C = unknown> {
  readonly componentInstance: C | null = null;
  _id: number;
  private afterClosedSubject = new Subject<unknown>();

  constructor(private readonly overlay: OverlayRef) {
    this._id = Math.round(Math.random() * 10000);
  }

  close() {
    this.overlay.dispose();
    this.afterClosedSubject.next(null);
    this.afterClosedSubject.complete();
    (this as { componentInstance: C | null }).componentInstance = null;
  }

  isVisible() {
    return this.overlay && this.overlay.overlayElement;
  }

  getPosition() {
    return this.overlay.overlayElement.getBoundingClientRect();
  }

  /**
   * An Observable that notifies when the overlay has closed
   */
  public afterClosed(): Observable<unknown> {
    return this.afterClosedSubject.asObservable();
  }
}
