import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ToasterService,
  TOAST_COMPONENT,
} from '@mysas/portal/shared/data-access-toaster';
import { MessageToastComponent } from './message-toast.component';

@NgModule({
  declarations: [MessageToastComponent],
  imports: [CommonModule],
  providers: [
    {
      provide: TOAST_COMPONENT,
      useValue: MessageToastComponent,
    },
    ToasterService,
  ],
  exports: [MessageToastComponent],
})
export class MessageToastModule {}
