<nav class="left-nav" *transloco="let t">
  <div class="nav-content">
    <section class="nav-group">
      <ul class="nav-list">
        <li>
          <a
            routerLink="/"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            class="nav-link"
          >
            <svg
              class="nav-link__icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.71429 1.14286V5.71429H1.14286V1.14286H5.71429ZM1.14286 0C0.512 0 0 0.512 0 1.14286V5.71429C0 6.34514 0.512 6.85714 1.14286 6.85714H5.71429C6.34514 6.85714 6.85714 6.34514 6.85714 5.71429V1.14286C6.85714 0.512 6.34514 0 5.71429 0H1.14286ZM14.8571 1.14286V5.71429H10.2857V1.14286H14.8571ZM10.2857 0C9.65486 0 9.14286 0.512 9.14286 1.14286V5.71429C9.14286 6.34514 9.65486 6.85714 10.2857 6.85714H14.8571C15.488 6.85714 16 6.34514 16 5.71429V1.14286C16 0.512 15.488 0 14.8571 0H10.2857ZM5.71429 10.2857V14.8571H1.14286V10.2857H5.71429ZM1.14286 9.14286C0.512 9.14286 0 9.65486 0 10.2857V14.8571C0 15.488 0.512 16 1.14286 16H5.71429C6.34514 16 6.85714 15.488 6.85714 14.8571V10.2857C6.85714 9.65486 6.34514 9.14286 5.71429 9.14286H1.14286ZM14.8571 10.2857V14.8571H10.2857V10.2857H14.8571ZM10.2857 9.14286C9.65486 9.14286 9.14286 9.65486 9.14286 10.2857V14.8571C9.14286 15.488 9.65486 16 10.2857 16H14.8571C15.488 16 16 15.488 16 14.8571V10.2857C16 9.65486 15.488 9.14286 14.8571 9.14286H10.2857Z"
                fill="black"
              />
            </svg>
            <span class="nav-link__text">{{ t('leftnavDashboard.link') }}</span>
          </a>
        </li>

        <li>
          <a class="nav-link" routerLink="/orders" routerLinkActive="active">
            <svg
              class="nav-link__icon"
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.14286 2.57142C0.512 2.57142 0 2.05942 0 1.42856C0 0.797706 0.512 0.285706 1.14286 0.285706C1.77371 0.285706 2.28571 0.797706 2.28571 1.42856C2.28571 2.05942 1.77371 2.57142 1.14286 2.57142ZM16 1.42856H4.57143V2.57142H16V1.42856ZM16 5.99999H4.57143V7.14285H16V5.99999ZM16 10.5714H4.57143V11.7143H16V10.5714ZM2.28571 5.99999C2.28571 5.36913 1.77371 4.85713 1.14286 4.85713C0.512 4.85713 0 5.36913 0 5.99999C0 6.63085 0.512 7.14285 1.14286 7.14285C1.77371 7.14285 2.28571 6.63085 2.28571 5.99999ZM2.28571 10.5714C2.28571 9.94056 1.77371 9.42856 1.14286 9.42856C0.512 9.42856 0 9.94056 0 10.5714C0 11.2023 0.512 11.7143 1.14286 11.7143C1.77371 11.7143 2.28571 11.2023 2.28571 10.5714Z"
                fill="black"
              />
            </svg>

            <span class="nav-link__text">{{ t('leftnavMyOrders.link') }}</span>
          </a>
        </li>
      </ul>
    </section>

    <section class="nav-group">
      <h6 class="nav-group__header">
        {{ t('leftnavSupport.title') }}
      </h6>
      <ul class="nav-list">
        <li class="nav-link nav-link__external">
          <a
            href="https://support.sas.com/ctx/tracks/introfilter"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavTracksLink.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8571 0H1.14286C0.514286 0 0 0.514286 0 1.14286V14.8571C0 15.4857 0.514286 16 1.14286 16H14.8571C15.4857 16 16 15.4857 16 14.8571V1.14286C16 0.514286 15.4857 0 14.8571 0ZM14.8571 14.8571H1.14286V12.5714H14.8571V14.8571ZM14.8571 11.4286H1.14286V9.14286H14.8571V11.4286ZM14.8571 8H1.14286V5.71429H14.8571V8ZM14.8571 4.57143H1.14286V2.28571H14.8571V4.57143Z"
                fill="black"
              />
            </svg>
            <span class="nav-link__text">{{ t('leftnavMyTracks.link') }}</span>
          </a>
        </li>
        <li class="nav-link nav-link__external">
          <a
            [href]="myCasesLink"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavMyCasesLink.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.212,13H1.003c-0.55,0-1-0.45-1-1c0,0-0.005-8.993-0.002-9l0.796-1.584C0.924,1.161,1.184,1,1.468,1h4.058 c0.292,0,0.56,0.165,0.69,0.427L7.003,3h4.998c0.552,0,1,0.448,1,1v1.597c-0.384-0.48-1-0.597-1-0.597h-11l0.001,7h7 C8.003,12.274,8.095,12.642,8.212,13z M13.086,10.32C12.623,10.773,12.095,11,11.501,11c-0.598,0-1.125-0.227-1.577-0.68 c-0.281,0.183-0.505,0.422-0.672,0.719S9.002,11.584,9.002,12s0.491,1.554,0.491,1.554C9.594,13.808,9.746,14,10.002,14H13 c0.258,0,0.49-0.224,0.581-0.454c0,0,0.419-1.146,0.419-1.546s-0.082-0.664-0.246-0.961S13.367,10.503,13.086,10.32z M11.501,6 c-0.828,0-1.499,0.895-1.499,2s0.671,2,1.499,2S13,9.105,13,8S12.329,6,11.501,6z"
                fill="black"
              />
            </svg>
            <span class="nav-link__text">{{ t('leftnavMyCases.link') }}</span>
          </a>
        </li>

        <li class="nav-link nav-link__external">
          <a
            [href]="createCaseLink"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavCreateCaseLink.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14,7.656V12c0,0.55-0.45,1-1,1H1c-0.55,0-1-0.45-1-1c0,0,0.002-8.993,0.005-9l0.787-1.584 C0.919,1.161,1.178,1,1.463,1h3.794C4.472,1.951,4,3.17,4,4.5C4,4.669,4.01,4.835,4.025,5H1v7h12V8.743 C13.381,8.428,13.716,8.061,14,7.656z M5,4.5C5,4.775,5.225,5,5.5,5h2.793L6.848,6.445c-0.194,0.194-0.194,0.513,0,0.707 s0.513,0.194,0.707,0L9,5.707V8.5C9,8.775,9.225,9,9.5,9S10,8.775,10,8.5V5.707l1.445,1.445c0.194,0.194,0.513,0.194,0.707,0 s0.194-0.513,0-0.707L10.707,5H13.5C13.775,5,14,4.775,14,4.5C14,4.225,13.775,4,13.5,4h-2.793l1.445-1.445 c0.194-0.194,0.194-0.513,0-0.707s-0.513-0.194-0.707,0L10,3.293V0.5C10,0.225,9.775,0,9.5,0S9,0.225,9,0.5v2.793L7.555,1.848 c-0.194-0.194-0.513-0.194-0.707,0s-0.194,0.513,0,0.707L8.293,4H5.5C5.225,4,5,4.225,5,4.5z"
                fill="black"
              />
            </svg>
            <span class="nav-link__text">{{
              t('leftnavCreateCase.link')
            }}</span>
          </a>
        </li>
        <li class="nav-link nav-link__external">
          <a
            href="https://learn.sas.com/my/"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavTrainingLink.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 0.142853L0 3.57142L8 8.14285L14.8571 4.224V9.85714C14.8571 10.1726 15.1131 10.4286 15.4286 10.4286C15.744 10.4286 16 10.1726 16 9.85714V3.57142L8 0.142853ZM8 6.82628L2.56 3.71771L8 1.38628L13.44 3.71771L8 6.82628ZM12.5714 6.84685V8.14285C12.5714 8.88799 10.4423 10.4251 8 11.4766C5.55771 10.4251 3.42857 8.88799 3.42857 8.14285V6.84685L2.28571 6.19428V8.14285C2.28571 10.4286 8 12.7143 8 12.7143C8 12.7143 13.7143 10.4286 13.7143 8.14285V6.19428L12.5714 6.84685Z"
                fill="black"
              />
            </svg>
            <span class="nav-link__text">{{ t('leftnavTraining.link') }}</span>
          </a>
        </li>

        <li class="nav-link nav-link__external">
          <a
            href="https://www.sas.com/en_us/learn/ask-the-expert-webinars.geo.html"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavAskTheExpertLink.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.78576 5.71429C2.78576 3.82057 3.80861 2.28571 5.07147 2.28571C6.33433 2.28571 7.35718 3.82057 7.35718 5.71429C7.35718 7.608 6.33433 9.14286 5.07147 9.14286C3.80861 9.14286 2.78576 7.608 2.78576 5.71429ZM7.20404 9.29257C6.62118 9.90743 5.88861 10.2857 5.07147 10.2857C4.25433 10.2857 3.52176 9.90743 2.9389 9.29257C2.36518 9.456 1.84404 9.74514 1.43147 10.1497C0.572041 10.9909 0.324041 12.2651 0.618898 13.4194L1.03261 15.5383C1.08518 15.8069 1.32061 16 1.59376 16H8.54918C8.82233 16 9.05775 15.8069 9.11033 15.5383L9.52404 13.4194C9.8189 12.2651 9.5709 10.9909 8.71147 10.1497C8.2989 9.74629 7.77775 9.456 7.20404 9.29257ZM14.2143 0H7.35718C6.50233 0 5.76175 0.481143 5.36976 1.18171C5.7709 1.22629 6.15147 1.34629 6.49776 1.55429C6.70804 1.30743 7.00975 1.14286 7.35718 1.14286H14.2143C14.844 1.14286 15.3572 1.65486 15.3572 2.28571V5.71429C15.3572 6.34514 14.844 6.85714 14.2143 6.85714H8.38347C8.3069 7.26514 8.18918 7.64571 8.03718 8H10.7858V11.4286L13.0715 8H14.2143C15.4715 8 16.5 6.97143 16.5 5.71429V2.28571C16.5 1.02857 15.4715 0 14.2143 0Z"
                fill="black"
              />
            </svg>
            <span class="nav-link__text">{{
              t('leftnavAskTheExpert.link')
            }}</span>
          </a>
        </li>

        <li class="nav-link nav-link__external">
          <a
            [href]="portalHelpLink"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavPortalHelpLink.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.16343 9.54972C7.16343 9.11086 7.22743 8.74971 7.35543 8.46629C7.48343 8.18286 7.72114 7.87657 8.06857 7.54743L8.68571 6.95771C8.88686 6.77486 9.04229 6.58743 9.152 6.39543C9.42629 5.91657 9.35543 5.22857 8.96686 4.832C8.45943 4.31543 7.50971 4.36571 7.03314 4.89371C6.78171 5.17257 6.64686 5.50857 6.62857 5.90171L4.94171 5.75086C5.04229 4.89143 5.38514 4.21486 5.97029 3.72114C6.94743 2.896 8.46286 2.75657 9.61143 3.28914C10.8217 3.85029 11.3931 5.34171 10.9006 6.58743C10.7954 6.85257 10.6514 7.09029 10.4686 7.30057C10.2857 7.51086 10.0251 7.76229 9.68686 8.05486C9.312 8.384 9.06743 8.64686 8.95314 8.84343C8.83886 9.04 8.78171 9.33029 8.78171 9.71429V10.0709H7.16343V9.54972ZM7.98629 10.9074C9.40114 10.9074 9.38514 13.0171 7.98629 13.0194C6.57829 13.0206 6.57371 10.9074 7.98629 10.9074ZM8 0C3.58171 0 0 3.58171 0 8C0 12.4183 3.58171 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58171 12.4183 0 8 0ZM8 14.8571C4.21943 14.8571 1.14286 11.7806 1.14286 8C1.14286 4.21943 4.21943 1.14286 8 1.14286C11.7806 1.14286 14.8571 4.21943 14.8571 8C14.8571 11.7806 11.7806 14.8571 8 14.8571Z"
                fill="black"
              />
            </svg>
            <span class="nav-link__text">{{ t('leftnavHelp.link') }}</span>
          </a>
        </li>
        <li class="nav-list__text">
          <p>{{ t('leftnavTracksToCases.txt') }}</p>
        </li>
      </ul>
    </section>

    <section class="nav-group">
      <h6 class="nav-group__header">
        {{ t('leftnavManage.title') }}
      </h6>
      <ul class="nav-list">
        <li class="nav-link nav-link__external">
          <a
            href="https://support.sas.com/sastools/sites"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavOpenSitesLink.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2143 8V0H2.78571V8H0.5V16H16.5V8H14.2143ZM10.7857 2.28571H13.0714V4.57143H10.7857V2.28571ZM10.7857 5.71429H13.0714V8H10.7857V5.71429ZM10.7857 9.14286H13.0714V11.4286H10.7857V9.14286ZM10.7857 12.5714H13.0714V14.8571H10.7857V12.5714ZM7.35714 2.28571H9.64286V4.57143H7.35714V2.28571ZM7.35714 5.71429H9.64286V8H7.35714V5.71429ZM7.35714 9.14286H9.64286V11.4286H7.35714V9.14286ZM7.35714 12.5714H9.64286V14.8571H7.35714V12.5714ZM3.92857 2.28571H6.21429V4.57143H3.92857V2.28571ZM3.92857 5.71429H6.21429V8H3.92857V5.71429ZM3.92857 9.14286H6.21429V11.4286H3.92857V9.14286ZM3.92857 12.5714H6.21429V14.8571H3.92857V12.5714Z"
                fill="black"
              />
            </svg>

            <span class="nav-link__text">{{
              t('leftnavInstallationSites.link')
            }}</span>
          </a>
        </li>
        <li class="nav-link nav-link__external">
          <a
            href="https://support.sas.com/sastools/reprequest"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavOpenSupportPer.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="11"
              height="16"
              viewBox="0 0 11 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.21434 3.42857C3.21434 1.53486 4.2372 0 5.50006 0C6.76292 0 7.78577 1.53486 7.78577 3.42857C7.78577 5.32229 6.76292 6.85714 5.50006 6.85714C4.2372 6.85714 3.21434 5.32229 3.21434 3.42857ZM9.14006 7.864C8.72749 7.45943 8.20634 7.17029 7.63263 7.00686C7.04977 7.62171 6.3172 8 5.50006 8C4.68292 8 3.95034 7.62171 3.36749 7.00686C2.79377 7.17029 2.27263 7.45943 1.86006 7.864C1.00063 8.70514 0.75263 9.97943 1.04749 11.1337L1.90806 15.5383C1.96063 15.8057 2.19492 16 2.4692 16H8.53206C8.8052 16 9.04063 15.8069 9.0932 15.5383L9.95377 11.1337C10.2475 9.97943 9.99949 8.70514 9.14006 7.864Z"
                fill="black"
              />
            </svg>

            <span class="nav-link__text">{{
              t('leftnavSupportPersonnel.link')
            }}</span>
          </a>
        </li>
        <li class="nav-link nav-link__external">
          <a
            href="https://support.sas.com/en/sas-administrators/redesignate.html"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavOpenDesignate.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="11"
              height="16"
              viewBox="0 0 11 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.21434 3.42857C3.21434 1.53486 4.2372 0 5.50006 0C6.76292 0 7.78577 1.53486 7.78577 3.42857C7.78577 5.32229 6.76292 6.85714 5.50006 6.85714C4.2372 6.85714 3.21434 5.32229 3.21434 3.42857ZM9.14006 7.864C8.72749 7.45943 8.20634 7.17029 7.63263 7.00686C7.04977 7.62171 6.3172 8 5.50006 8C4.68292 8 3.95034 7.62171 3.36749 7.00686C2.79377 7.17029 2.27263 7.45943 1.86006 7.864C1.00063 8.70514 0.75263 9.97943 1.04749 11.1337L1.90806 15.5383C1.96063 15.8057 2.19492 16 2.4692 16H8.53206C8.8052 16 9.04063 15.8069 9.0932 15.5383L9.95377 11.1337C10.2475 9.97943 9.99949 8.70514 9.14006 7.864Z"
                fill="black"
              />
            </svg>

            <span class="nav-link__text">{{
              t('leftnavRedesignatePersonnel.link')
            }}</span>
          </a>
        </li>
        <li class="nav-link nav-link__external">
          <a
            href="https://support.sas.com/adminservices/contact.html"
            target="_blank"
            class="nav-link__link"
            attr.aria-label="{{ t('leftnavOpenLicOps.aria') }}"
          >
            <svg
              class="nav-link__icon"
              width="11"
              height="16"
              viewBox="0 0 11 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.21434 3.42857C3.21434 1.53486 4.2372 0 5.50006 0C6.76292 0 7.78577 1.53486 7.78577 3.42857C7.78577 5.32229 6.76292 6.85714 5.50006 6.85714C4.2372 6.85714 3.21434 5.32229 3.21434 3.42857ZM9.14006 7.864C8.72749 7.45943 8.20634 7.17029 7.63263 7.00686C7.04977 7.62171 6.3172 8 5.50006 8C4.68292 8 3.95034 7.62171 3.36749 7.00686C2.79377 7.17029 2.27263 7.45943 1.86006 7.864C1.00063 8.70514 0.75263 9.97943 1.04749 11.1337L1.90806 15.5383C1.96063 15.8057 2.19492 16 2.4692 16H8.53206C8.8052 16 9.04063 15.8069 9.0932 15.5383L9.95377 11.1337C10.2475 9.97943 9.99949 8.70514 9.14006 7.864Z"
                fill="black"
              />
            </svg>

            <span class="nav-link__text">{{ t('leftnavConLicOps.link') }}</span>
          </a>
        </li>
      </ul>
    </section>
  </div>
</nav>
