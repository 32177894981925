import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  IToastConfig,
  TOAST_CONFIG,
  ToastRef,
} from '@mysas/portal/shared/data-access-toaster';
import {
  MessageToastAnimationState,
  toastAnimations,
} from './message-toast-animation';

@Component({
  selector: 'message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toastAnimations.fadeToast],
})
export class MessageToastComponent implements OnInit, OnDestroy {
  animationState: MessageToastAnimationState = 'default';

  private intervalId: number | undefined;

  constructor(
    @Inject(ToastRef) readonly ref: ToastRef<MessageToastComponent>,
    @Inject(TOAST_CONFIG) public config: IToastConfig,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.intervalId = window.setTimeout(() => {
      this.animationState = 'closing';
      // because this uses OnPush, we need to call detectChanges() here
      // so that the animation state triggers!
      this.cdRef.detectChanges();
    }, 5000);
  }

  ngOnDestroy(): void {
    clearTimeout(this.intervalId);
  }

  close() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as MessageToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
