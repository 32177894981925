import { ILink } from '@mysas/shared/util-environment';
import { LangDefinition } from '@ngneat/transloco';
import { i18nConfig } from './i18n.config';
import { ILocale } from './locale.interfaces';

// generate the localized link, right now this is for help only
export function getLocalizedLink(link: ILink, locale: ILocale): string {
  let finalLanguage = locale.language;
  if (locale.script == 'Hans') {
    finalLanguage = finalLanguage + '-CN';
  } else if (locale.script == 'Hant') {
    finalLanguage = finalLanguage + '-TW';
  }
  const finalLink = link.url.replace('{{language}}', finalLanguage);

  return finalLink;
}

export function getLanguageDefinitions(): LangDefinition[] {
  if (
    i18nConfig.availableLangs !== undefined &&
    i18nConfig.availableLangs.length > 0
  ) {
    return i18nConfig.availableLangs.map((i) =>
      typeof i === 'string' ? ({ id: i, label: i } as LangDefinition) : i
    ) as LangDefinition[];
  }
  return [];
}