import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { IAssetPanelData, PANEL_DATA } from './asset-panel.component';
import { PanelRef } from './panel-ref';

export interface IAssetPanelConfig {
  data?: IAssetPanelData;
}
@Injectable({
  providedIn: 'root',
})
export class PanelService {
  constructor(private overlay: Overlay, private injector: Injector) {}
  open<T>(
    component: ComponentType<T>,
    config?: IAssetPanelConfig
  ): PanelRef<T> {
    // Globally centered position strategy
    const positionStrategy = this.overlay.position().global().right();
    // Create the overlay with customizable options
    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'sasds-dialog__backdrop',
      panelClass: 'overlay-panel',
    });

    // Create dialogRef to return
    const panelRef = new PanelRef<T>(overlayRef);

    // Create injector to be able to reference the DialogRef from within the component
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: PanelRef, useValue: panelRef },
        { provide: PANEL_DATA, useValue: config?.data },
      ],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);
    const componentRef = overlayRef.attach(portal);
    (panelRef as { componentInstance: T }).componentInstance =
      componentRef.instance;

    return panelRef;
  }
}
