import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeatureNotFoundModule } from '@mysas/portal/fallback-pages/feature-not-found';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { NotFoundComponent } from '@mysas/portal/fallback-pages/feature-not-found';

@NgModule({
  imports: [
    CommonModule,

    RouterModule.forChild([
      {
        path: 'login/redirect',
        loadChildren: () =>
          import('@mysas/portal/fallback-pages/feature-login-redirect').then(
            (m) => m.FeatureLoginRedirectModule
          ),
      },
      {
        path: 'signed-out',
        loadChildren: () =>
          import('@mysas/portal/fallback-pages/feature-signed-out').then(
            (m) => m.FeatureSignedOutModule
          ),
      },
      {
        path: 'error',
        loadChildren: () =>
          import('@mysas/portal/fallback-pages/feature-error').then(
            (m) => m.FeatureErrorModule
          ),
      },
      { path: 'login/callback', component: OktaCallbackComponent },
      {
        path: '**',
        pathMatch: 'full',
        component: NotFoundComponent,
        // redirectTo: 'notfound',
      },
    ]),
    FeatureNotFoundModule,
  ],
})
export class PortalFallbackPagesShellModule {}
