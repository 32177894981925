import { Injectable } from '@angular/core';
import { IPreference } from '@mysas/shared/data-access-orders';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';

import * as PreferencesActions from './preferences.actions';
import * as PreferencesSelectors from './preferences.selectors';

@Injectable()
export class PreferencesFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.select(PreferencesSelectors.getPreferencesLoaded);
  error$ = this.store.select(PreferencesSelectors.getPreferencesError);
  allPreferences$ = this.store.select(PreferencesSelectors.getAllPreferences);
  selectedPreferences$ = this.store.select(PreferencesSelectors.getSelected);

  subscribedToVersionUpdates$ = this.store.select(
    PreferencesSelectors.getSubscribedToVersionUpdate
  );

  constructor(private readonly store: Store) {}

  updatePreference(preference: IPreference) {
    this.store.dispatch(
      PreferencesActions.UpdatePreferences.update({ preference })
    );
  }

  unsubscribeFromVersionEmail() {
    this.allPreferences$
      .pipe(
        take(1),
        map((prefs) => prefs.find((p) => p.id === 'newVersion') as IPreference)
      )
      .subscribe((pref) => {
        this.store.dispatch(
          PreferencesActions.UpdatePreferences.update({
            preference: {
              ...pref,
              subscribed: 'false',
              subscribed_bool: false,
            },
          })
        );
      });
  }

  subscribeToVersionEmail() {
    this.allPreferences$
      .pipe(
        take(1),
        map((prefs) => prefs.find((p) => p.id === 'newVersion') as IPreference)
      )
      .subscribe((pref) => {
        this.store.dispatch(
          PreferencesActions.UpdatePreferences.update({
            preference: {
              ...pref,
              subscribed: 'true',
              subscribed_bool: true,
            },
          })
        );
      });
  }
}
