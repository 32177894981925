import { Observable, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

// can be called by angular or nestjs, so no common
// config service can be invoked to set defaults

/**
 *
 * enables retries when using the pipe operator retryWhen
 * @example
 *    .pipe(
 *       retryWhen(
 *         retryStrategy({maxRetryAttempts: 3})
 *       ),
 *    )
 */
export const retryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 500,
    excludedStatusCodes = [404],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (
          retryAttempt > maxRetryAttempts ||
          excludedStatusCodes.find((e) => e === error.status)
        ) {
          return throwError(() => error);
        }
        // if (error instanceof HttpErrorResponse) {
        // logger.debug(
        //   `Attempt ${retryAttempt}: Retrying request to ${error.url} in ${
        //     retryAttempt * scalingDuration
        //   }ms`
        // );
        // } else {
        // logger.debug(
        //   `Attempt ${retryAttempt}: retrying in ${
        //     retryAttempt * scalingDuration
        //   }ms`
        // );
        // }

        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
      })
    );
  };
