import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IOrderDetail } from '@mysas/shared/data-access-orders';
import { environment } from '@mysas/shared/util-environment';

@Component({
  selector: 'mysas-order-basic-info',
  templateUrl: './order-basic-info.component.html',
  styleUrls: ['./order-basic-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBasicInfoComponent {
  @Input() order: Partial<IOrderDetail> | undefined;
  createCaseLink = environment.links['csmCreateCase'].url;
}
