<div class="dialog" *transloco="let t">
  <div class="dialog__content">
    <div class="row">
      <div class="col">
        <h2 class="dialog__title">
          {{ t('emailPrefDialogTitle.title') }}
        </h2>
      </div>
    </div>

    <ng-container
      *ngIf="subscribedToVersionUpdates$ | async; else notSubscribed"
    >
      <div class="row">
        <div class="col" style="position: relative">
          <span>{{ t('emailPrefDialogVersionUpdates.title') }}</span>
          <button
            class="btn btn-standard-no-bd unsubscribe-btn"
            (click)="unsubscribe()"
          >
            {{ t('emailPrefDialogUnsub.label') }}
          </button>
          <spinner *ngIf="(loaded$ | async) === false"></spinner>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="dialog__text">
            {{ t('emailPrefDialogSubText.txt') }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-template #notSubscribed>
      <div class="row">
        <div class="col" style="position: relative">
          <span>{{ t('emailPrefDialogVersionUpdates.title') }}</span>
          <button class="btn btn-primary subscribe-btn" (click)="subscribe()">
            {{ t('emailPrefDialogSub.label') }}
          </button>
          <spinner *ngIf="(loaded$ | async) === false"></spinner>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="dialog__text">
            {{ t('emailPrefDialogUnsubText.txt') }}
          </p>
        </div>
      </div>
    </ng-template>

    <div class="row" *ngIf="error$ | async as error">
      <div class="col">
        <p class="dialog__text" style="color: var(--color-status-alert)">
          {{ error }}
        </p>
      </div>
    </div>
  </div>

  <div class="dialog__footer">
    <div class="row">
      <div class="col d-flex justify-content-end">
        <button class="btn btn-primary" (click)="close()">
          {{ t('emailPrefDialogClose.label') }}
        </button>
      </div>
    </div>
  </div>

  <div class="dialog__dismiss">
    <button class="btn btn-standard-no-bd-bg" (click)="close()"></button>
  </div>
</div>
