import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { IPreference } from '@mysas/shared/data-access-orders';
import * as PreferencesActions from './preferences.actions';

export const PREFERENCES_FEATURE_KEY = 'preferences';

export interface PreferencesState extends EntityState<IPreference> {
  selectedId?: string | number; // which Preferences record has been selected
  loaded: boolean; // has the Preferences list been loaded
  error?: string | null; // last known error (if any)
}

export interface PreferencesPartialState {
  readonly [PREFERENCES_FEATURE_KEY]: PreferencesState;
}

export const preferencesAdapter: EntityAdapter<IPreference> =
  createEntityAdapter<IPreference>({
    selectId: (pref) => pref.pKey,
  });

export const initialPreferencesState: PreferencesState =
  preferencesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialPreferencesState,
  on(
    PreferencesActions.LoadPreferences.load,
    PreferencesActions.UpdatePreferences.update,
    (state): PreferencesState => ({
      ...state,
      loaded: false,
      error: null,
    })
  ),
  on(PreferencesActions.LoadPreferences.success, (state, { preferences }) =>
    preferencesAdapter.setAll(preferences, { ...state, loaded: true })
  ),
  on(
    PreferencesActions.LoadPreferences.failure,
    PreferencesActions.UpdatePreferences.failure,
    (state, { error }): PreferencesState => ({
      ...state,
      error,
      loaded: true,
    })
  ),
  on(PreferencesActions.UpdatePreferences.success, (state, { preference }) =>
    preferencesAdapter.setOne(preference, { ...state, loaded: true })
  )
);

export function preferencesReducer(
  state: PreferencesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
