import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { OrderOverviewFacade } from '@mysas/portal/orders/data-access-orders';
import { DialogRef } from '@mysas/portal/shared/data-access-dialog';
import { AlertComponentModule } from '@mysas/portal/shared/ui-layout';
import { IAddUserToOrder } from '@mysas/shared/data-access-orders';
import { TranslocoModule } from '@ngneat/transloco';
import { map } from 'rxjs';

type IAddUserForm = {
  [K in keyof IAddUserToOrder]: FormControl<string>;
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    A11yModule,
    ReactiveFormsModule,
    TranslocoModule,
    AlertComponentModule,
  ],
  selector: 'mysas-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserDialogComponent implements AfterViewInit {
  @ViewChild('firstFocus')
  firstFocus!: ElementRef;
  newUserForm = new FormGroup<IAddUserForm>({
    label: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(128)],
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
      updateOn: 'blur',
    }),
    role: new FormControl('user', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  private readonly orderOverviewFacade = inject(OrderOverviewFacade);

  processing$ = this.orderOverviewFacade.addUserRequest$.pipe(
    map(({ processing }) => processing)
  );
  error$ = this.orderOverviewFacade.addUserRequest$.pipe(
    map(({ error }) => error)
  );

  constructor(private dialogRef: DialogRef) {}

  ngAfterViewInit(): void {
    this.firstFocus?.nativeElement.focus();
  }

  close() {
    this.dialogRef.close();
  }

  sendInvite() {
    const payload = this.newUserForm.getRawValue();
    this.orderOverviewFacade.inviteNewUser(payload);
  }
}
