import { CommonModule } from '@angular/common';
import {
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnInit,
  Renderer2,
} from '@angular/core';

export type BadgeStatusColorType =
  | 'alert'
  | 'success'
  | 'information'
  | 'warning';

/**
 * NOT IN USE AT THIS TIME! Opted for block `<div>` elements with styling instead
 * of attempting to dynamically update an SVG. The added circle would appear clipped
 * because 3/4 of it were outside the bounds of the SVG element.
 *
 * @export
 * @class HasBadgeDirective
 * @typedef {HasBadgeDirective}
 * @implements {OnInit}
 */
@Directive({
  selector: '[hasBadge]',
})
export class HasBadgeDirective implements OnInit {
  /**
   * Allows for passing in a state name, which gets turned into a CSS variable
   * at initialization
   *
   * @todo if the template doesn't pass in a value, the code doesn't use the default for some reason
   * @type {BadgeStatusColorType}
   */
  @Input('hasBadge') hasBadge: BadgeStatusColorType = 'alert';
  @Input('size') diameter = 8;

  private elWidth: number;
  private elHeight: number;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
    this.elWidth = +elRef.nativeElement.attributes.width.value;
    this.elHeight = +elRef.nativeElement.attributes.height.value;
  }

  ngOnInit(): void {
    const circle = this.renderer.createElement('circle', 'svg');
    this.renderer.setAttribute(
      circle,
      'cx',
      `${this.elWidth - this.diameter / 2}`
    );
    this.renderer.setAttribute(circle, 'cy', `${this.diameter / 2}`);
    this.renderer.setAttribute(circle, 'r', `${this.diameter / 2}`);
    this.renderer.setAttribute(
      circle,
      'style',
      `fill:var(--${this.hasBadge}-normal-fg)`
    );
    this.renderer.appendChild(this.elRef.nativeElement, circle);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [HasBadgeDirective],
  exports: [HasBadgeDirective],
})
export class HasBadgeDirectiveModule {}
