import { INotification } from '@mysas/shared/data-access-notifications';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  notificationsAdapter,
  NotificationsState,
  NOTIFICATIONS_FEATURE_KEY,
} from './notifications.reducer';

// Lookup the 'Notifications' feature state managed by NgRx
export const getNotificationsState = createFeatureSelector<NotificationsState>(
  NOTIFICATIONS_FEATURE_KEY
);

const { selectAll, selectEntities } = notificationsAdapter.getSelectors();

export const getNotificationsLoaded = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.loaded
);

export const getNotificationsError = createSelector(
  getNotificationsState,
  (state: NotificationsState) => state.error
);

export const getAllNotifications = createSelector(
  getNotificationsState,
  (state: NotificationsState) => selectAll(state)
);

export const getNotificationsEntities = createSelector(
  getNotificationsState,
  (state: NotificationsState) => selectEntities(state)
);

export const getUnreadNotifications = createSelector(
  getAllNotifications,
  (entities: INotification[]) => entities.filter((e) => !e.dismissed)
);
