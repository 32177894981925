<ng-container *transloco="let t">
  <ng-container
    *ngIf="{ busy: processing$ | async, error: error$ | async } as obs"
  >
    <div
      cdkFocusTrap
      role="dialog"
      aria-modal="true"
      class="dialog"
      aria-labelledby="add-user-dialog-title"
    >
      <div class="dialog__content" [formGroup]="newUserForm">
        <div class="row">
          <div class="col">
            <h2 id="add-user-dialog-title" class="dialog__title">
              {{ t('addUserDlg.title') }}
            </h2>
          </div>
        </div>

        <div class="row" *ngIf="obs.error">
          <div class="col">
            <alert state="alert" [dismissable]="true">{{ obs.error }}</alert>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="label" id="emailLabel">{{
                t('addUserDlgEmail.label')
              }}</label>
              <input
                #firstFocus
                tabindex="0"
                type="email"
                id="add-user-to-order-email"
                class="form-control"
                aria-labelledby="emailLabel"
                formControlName="email"
              />

              <small
                aria-describedby="add-user-to-order-email"
                class="validation-error"
                *ngIf="
                  newUserForm.get('email')?.hasError('required') &&
                  newUserForm.get('email')?.touched
                "
                >{{ t('addUserDlgEmailValidationRequired.txt') }}</small
              >
              <small
                aria-describedby="add-user-to-order-email"
                class="validation-error"
                *ngIf="newUserForm.get('email')?.hasError('email')"
                >{{ t('addUserDlgEmailValidationFormat.txt') }}</small
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="label" id="nameLabel">{{
                t('addUserDlgName.label')
              }}</label>
              <input
                tabindex="0"
                id="add-user-to-order-name"
                type="text"
                class="form-control"
                aria-labelledby="nameLabel"
                formControlName="label"
              />
              <small
                aria-describedby="add-user-to-order-name"
                class="validation-error"
                *ngIf="newUserForm.get('label')?.hasError('maxlength')"
                >{{ t('addUserDlgNameValidationLength.txt') }}</small
              >
              <small
                aria-describedby="add-user-to-order-name"
                class="validation-error"
                *ngIf="
                  newUserForm.get('label')?.hasError('required') &&
                  newUserForm.get('label')?.touched
                "
                >{{ t('addUserDlgNameValidationRequired.txt') }}</small
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="label" id="roleLabel">{{
                t('addUserDlgRole.label')
              }}</label>
              <select
                id="add-user-to-order-role"
                tabindex="0"
                class="form-control"
                aria-labelledby="roleLabel"
                formControlName="role"
              >
                <option value="user">
                  {{ t('addUserDlgRoleUser.label') }}
                </option>
                <option value="owner">
                  {{ t('addUserDlgRoleOwner.label') }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog__footer">
        <div class="row">
          <div class="col d-flex justify-content-end">
            <button
              tabindex="0"
              class="btn btn-primary"
              attr.aria-label="{{ t('addUserDlgSubmitBtn.aria') }}"
              type="submit"
              [disabled]="newUserForm.invalid"
              (click)="sendInvite()"
            >
              <span>{{
                obs.busy
                  ? t('addUserDlgSending.txt')
                  : t('addUserDlgSubmitBtn.txt')
              }}</span>
            </button>
          </div>
        </div>
      </div>

      <div class="dialog__dismiss">
        <button
          tabindex="0"
          class="btn btn-standard-no-bd-bg"
          attr.aria-label="{{ t('addUserDlgClose.aria') }}"
          (click)="close()"
        ></button>
      </div>
    </div>
  </ng-container>
</ng-container>
