import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OrderOverviewEffects } from './order-overview-state/order-overview.effects';
import { OrderOverviewFacade } from './order-overview-state/order-overview.facade';
import * as fromOrderOverview from './order-overview-state/order-overview.reducer';
import { getSaver, SAVER } from './services/saver.provider';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature(
      fromOrderOverview.ORDER_OVERVIEW_FEATURE_KEY,
      fromOrderOverview.orderOverviewReducer
    ),
    EffectsModule.forFeature([OrderOverviewEffects]),
  ],
  providers: [
    OrderOverviewFacade,
    {
      provide: SAVER,
      useFactory: getSaver,
    },
  ],
})
export class DataAccessOrdersModule {}
