<ng-container *transloco="let t">
  <ng-container
    *ngIf="{
      loadingState: loadingState$ | async,
      error: error$ | async
    } as obs"
  >
    <div
      class="asset-panel"
      role="dialog"
      aria-describedby="assets-panel-downloads-header"
    >
      <h2 class="asset-panel__title" id="assets-panel-downloads-header">
        {{ t('assetsPanelDownloads.title') }}
      </h2>
      <div class="dialog__dismiss">
        <button
          #dismissAssetPanelButton
          tabindex="0"
          class="btn btn-standard-no-bd-bg"
          attr.aria-label="{{ t('addUserDlgClose.aria') }}"
          (click)="close()"
        ></button>
      </div>

      <!-- success state -->
      <ng-container *ngIf="obs.loadingState?.success">
        <div class="update-card" *ngIf="hasLicenseUpdate">
          <div class="update-card__header">
            <span>{{ t('assetsPanelUpdateAvail.title') }}</span>
          </div>
          <div class="update-card__content">
            <span class="version">{{
              t('assetsPanelVersion.label', {
                cadenceVersion: data.version.cadenceVersion
              })
            }}</span>
            <button tabindex="0" class="btn btn-primary" (click)="(false)">
              {{ t('assetsPanelDownloadLicense.label') }}
            </button>
          </div>
        </div>

        <div class="update-card" *ngIf="hasPatchUpdate">
          <div class="update-card__header">
            <span>{{ t('assetsPanelUpdateAvail.title') }}</span>
          </div>
          <div class="update-card__content">
            <span class="version">{{
              t('assetsPanelVersion.label', {
                cadenceVersion: data.version.cadenceVersion
              })
            }}</span>
            <button
              tabindex="0"
              class="btn btn-primary"
              (click)="triggerUpdateDownload('deploymentAssets')"
            >
              {{ t('assetsPanelDownloadAssets.label') }}
            </button>
          </div>
        </div>

        <ng-container *ngFor="let assetType of data.assetTypes">
          <h3 class="table-header">
            {{
              t('assetsPanelDownloadAssetType.label', {
                cadenceVersion: data.version.cadenceVersion,
                assetType: (assetType | assetType)
              })
            }}
          </h3>
          <table class="table history-table">
            <thead>
              <tr>
                <th>
                  {{ t('assetsPanelDownloadHistoryDate.label') }}
                </th>
                <th>
                  {{ t('assetsPanelDownloadHistoryBy.label') }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let record of data.version.downloadHistory">
                <tr *ngIf="record.assetType === assetType">
                  <td>
                    {{ record.date | translocoDate: { dateStyle: 'short' } }}
                  </td>
                  <td>{{ record.user }}</td>
                  <td>
                    <button
                      tabindex="0"
                      class="btn btn-standard-no-bd-bg"
                      (click)="downloadAsset(record)"
                      attr.aria-label="{{ t('assetsPanelDownloadAsset.aria') }}"
                      [disabled]="downloadInProgress$ | async"
                    >
                      <svg
                        localeOverrides="{}"
                        xmlns="http://www.w3.org/2000/svg"
                        class="download-icon"
                        width="14"
                        height="14"
                        version="1.1"
                        viewBox="0 0 14 14"
                        xml:space="preserve"
                      >
                        <title>{{ record.name }}</title>
                        <path
                          class="svgToolTipPane"
                          fill="none"
                          d="M0 0H14V14H0z"
                        ></path>
                        <path
                          class="iconColor"
                          d="M13,11v2c0,0.552-0.448,1-1,1H2c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1v3h10v-3 C12.552,10,13,10.448,13,11z M8,8V1c0-0.552-0.448-1-1-1C6.448,0,6,0.448,6,1v7H3l4,4l4-4H8z"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
      </ng-container>

      <!-- error state -->
      <ng-container *ngIf="obs.loadingState?.error">
        <div class="error-container">
          <!-- <alert state="alert" [dismissable]="false">{{ obs.error }}</alert> -->
          <mysas-loading-error
            [error]="obs.loadingState?.error"
          ></mysas-loading-error>
        </div>
      </ng-container>

      <!-- loading state -->
      <ng-container *ngIf="obs.loadingState?.status === 'LOADING'">
        <div class="loading-container">
          <spinner></spinner>
        </div>
      </ng-container>

      <p class="asset-panel__footer-text">
        {{ t('assetsPanelDownloadFooterNotice.txt') }}
      </p>
    </div>
  </ng-container>
</ng-container>
