import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { INotification } from '@mysas/shared/data-access-notifications';
import * as NotificationsActions from './notifications.actions';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface NotificationsState extends EntityState<INotification> {
  loaded: boolean; // has the Notifications list been loaded
  error?: string | null; // last known error (if any)
}

export interface NotificationsPartialState {
  readonly [NOTIFICATIONS_FEATURE_KEY]: NotificationsState;
}

export const notificationsAdapter: EntityAdapter<INotification> =
  createEntityAdapter<INotification>({
    selectId: (notification) => notification.slug,
  });

export const initialNotificationsState: NotificationsState =
  notificationsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialNotificationsState,
  on(NotificationsActions.addNotification, (state, { notification }) =>
    notificationsAdapter.addOne(notification, state)
  ),
  on(
    NotificationsActions.hydrateNotificationsFromStorage,
    (state): NotificationsState => ({
      ...state,
      loaded: false,
      error: null,
    })
  ),
  on(
    NotificationsActions.hydrateNotificationsSuccess,
    (state, { notifications }) =>
      notificationsAdapter.upsertMany(notifications, {
        ...state,
        loaded: true,
      })
  ),
  on(
    NotificationsActions.hydrateNotificationsFailure,
    (state, { error }): NotificationsState => ({
      ...state,
      error,
    })
  ),
  on(NotificationsActions.batchAddNotifications, (state, { notifications }) =>
    notificationsAdapter.upsertMany(
      notifications.map((n) => {
        if ((state.ids as string[]).includes(n.slug)) {
          return {
            slug: (state.entities[n.slug] as INotification).slug,
            dismissed: (state.entities[n.slug] as INotification).dismissed,
            title: n.title,
            text: n.text,
          };
        }
        return n;
      }),
      state
    )
  ),
  on(NotificationsActions.dismissNotification, (state, { notification }) =>
    notificationsAdapter.updateOne(
      { id: notification.slug, changes: { dismissed: true } },
      state
    )
  ),
  on(NotificationsActions.deleteNotification, (state, { slug }) =>
    notificationsAdapter.removeOne(slug, state)
  ),
  on(NotificationsActions.deleteMultipleNotifications, (state, { slugs }) =>
    notificationsAdapter.removeMany(slugs, state)
  ),
  on(NotificationsActions.deleteAllNotifications, (state) =>
    notificationsAdapter.removeAll(state)
  )
);

export function notificationsReducer(
  state: NotificationsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
