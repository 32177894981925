import { Inject, inject, Injectable } from '@angular/core';
import {
  DialogRef,
  DialogService,
} from '@mysas/portal/shared/data-access-dialog';
import { OrdersService } from '@mysas/portal/shared/data-access-orders';
import { ToasterService } from '@mysas/portal/shared/data-access-toaster';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { OrderOverviewSelectors } from '../..';
import {
  DATA_ACCESS_ORDERS_CONFIG,
  DataAccessOrdersConfig,
} from '../data-access-orders.config';

import * as OrderOverviewActions from './order-overview.actions';

@Injectable()
export class OrderOverviewEffects {
  private dialogRef: DialogRef | undefined;

  private toastService = inject(ToasterService);

  private translate = inject(TranslocoService);

  /**
   * This observable watches the router and uses the order number from the URL
   * to trigger a loading of many other resources:
   *
   *  │Page Loads with order number in URL
   *  │
   *  └► Load Order Details
   *  │
   *  │► Load Order Users
   *  │
   *  │► Load Order Cadences
   *  │
   *  └► Load Order Download History
   */
  orderNumber$: Observable<string> = this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({ payload }) => payload.event.url.startsWith('/orders/')),
    map(({ payload }) => {
      // due to lazy loading, this URL param is buried deep in the routerState
      const orderNum =
        payload.routerState.root.firstChild?.firstChild?.params['orderId'];
      return orderNum as string;
    })
  );

  /**
   * This effect watches for the `orderNumber$` observable to emit, which implies that
   * the browser navigated to a URL such as /orders/<orderNum>. This in turn triggers the loading
   * of a few different API calls.
   */
  triggerOrderDetails$ = createEffect(() => {
    return this.orderNumber$.pipe(
      map((orderNum) => OrderOverviewActions.LoadOrderDetail.load({ orderNum }))
    );
  });

  // onRouteInitLoadOrderUsers$ = createEffect(() => {
  //   return this.orderNumber$.pipe(
  //     map((orderNum) => OrderOverviewActions.LoadOrderUsers.load({ orderNum }))
  //   );
  // });

  triggerOrderUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.LoadOrderDetail.success),
      map(({ detail }) =>
        OrderOverviewActions.LoadOrderUsers.load({ orderNum: detail.number })
      )
    );
  });

  triggerOrderCadences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.LoadOrderDetail.success),
      filter(({ detail }) => detail.downloadStatus !== 'hold'),
      map(({ detail }) =>
        OrderOverviewActions.LoadOrderCadences.load({ orderNum: detail.number })
      )
    );
  });

  triggerOrderDownloadHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.LoadOrderDetail.success),
      filter(({ detail }) => detail.downloadStatus !== 'hold'),
      map(({ detail }) =>
        OrderOverviewActions.LoadOrderDownloadHistory.load({
          orderNum: detail.number,
        })
      )
    );
  });

  loadOrderDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.LoadOrderDetail.load),
      switchMap(({ orderNum }) => {
        return this.ordersService.getOrderDetails(orderNum).pipe(
          map(({ data }) => {
            return OrderOverviewActions.LoadOrderDetail.success({
              detail: data,
            });
          }),
          catchError((err: Error) =>
            of(
              OrderOverviewActions.LoadOrderDetail.failure({
                error: err.message,
              })
            )
          )
        );
      })
    );
  });

  alertOrderOnHold$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderOverviewActions.LoadOrderDetail.success),
        filter(({ detail }) => detail.downloadStatus === 'hold'),
        // TODO use this to show alert dialog!
        tap(() => {
          this.dialogService.open(this.config.unableToDownloadComponent);
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  loadOrderUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.LoadOrderUsers.load),
      switchMap(({ orderNum }) =>
        this.ordersService.getOrderUsers(orderNum).pipe(
          map(({ users }) =>
            OrderOverviewActions.LoadOrderUsers.success({ users })
          ),
          catchError((err: Error) => {
            return of(
              OrderOverviewActions.LoadOrderUsers.failure({
                error: err.message,
              })
            );
          })
        )
      )
    );
  });

  loadAvailableCadences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.LoadOrderCadences.load),
      switchMap(({ orderNum }) =>
        this.ordersService.getAvailableOrderCadences(orderNum).pipe(
          map(({ availableCadences }) =>
            OrderOverviewActions.LoadOrderCadences.success({
              availableCadences,
            })
          ),
          catchError((err: Error) =>
            of(
              OrderOverviewActions.LoadOrderCadences.failure({
                error: err.message,
              })
            )
          )
        )
      )
    );
  });

  loadDownloadHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.LoadOrderDownloadHistory.load),
      switchMap(({ orderNum }) =>
        this.ordersService.getOrderDownloadHistory(orderNum).pipe(
          map((downloadHistory) =>
            OrderOverviewActions.LoadOrderDownloadHistory.success({
              downloadHistory,
            })
          ),
          catchError((err: Error) =>
            of(
              OrderOverviewActions.LoadOrderDownloadHistory.failure({
                error: err.message,
              })
            )
          )
        )
      )
    );
  });

  addUserDialogOpened$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.openAddUserDialog),
      switchMap(({ component }) => {
        if (this.dialogRef) {
          this.dialogRef = undefined;
        }
        this.dialogRef = this.dialogService.open(component);
        return this.dialogRef.afterClosed();
      }),
      map(() => OrderOverviewActions.addUserReset())
    );
  });

  submitAddUserRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.addUser),
      switchMap(({ payload, orderNumber }) =>
        this.ordersService.addUserToOrder(orderNumber, payload).pipe(
          map((resp) => OrderOverviewActions.addUserSuccess({ user: resp })),
          catchError((err: Error) =>
            of(
              OrderOverviewActions.addUserFailure({
                error: err.message,
              })
            )
          )
        )
      )
    );
  });

  addUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.addUserSuccess),
      map(() =>
        OrderOverviewActions.closeAddUserDialog({
          toastType: 'success',
          text: this.translate.translate(
            'addUserToastSuccess.txt',
            {},
            'orderOverview'
          ),
        })
      )
    );
  });

  addUserDialogClosed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderOverviewActions.closeAddUserDialog),
        tap(({ toastType, text }) => {
          this.dialogRef?.close();
          this.toastService.open({
            type: toastType,
            text,
            position: { bottom: 128, right: 32 },
          });
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  reinviteUserToOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.ResendInviteActions.inviteRequested),
      switchMap(({ orderNumber, payload }) =>
        this.ordersService.reinviteUserToOrder(orderNumber, payload).pipe(
          map(() =>
            OrderOverviewActions.ResendInviteActions.inviteSuccess({
              email: payload.email,
            })
          ),
          catchError((err: Error) =>
            of(
              OrderOverviewActions.ResendInviteActions.inviteFailure({
                error: err.message,
              })
            )
          )
        )
      )
    );
  });

  inviteSuccessAlert$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderOverviewActions.ResendInviteActions.inviteSuccess),
        tap(() =>
          this.toastService.open({
            type: 'info',
            text: this.translate.translate(
              'resendInviteToastSuccess.txt',
              {},
              'orderOverview'
            ),
            position: { bottom: 128, right: 32 },
          })
        ),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  inviteFailureAlert$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderOverviewActions.ResendInviteActions.inviteFailure),
        tap(() =>
          this.toastService.open({
            // TODO - toasts don't have an error style, use info for now
            type: 'info',
            text: this.translate.translate(
              'resendInviteToastFailure.txt',
              {},
              'orderOverview'
            ),
            position: { bottom: 128, right: 32 },
          })
        ),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  removeUserFromOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.RemoveUserActions.removeRequested),
      switchMap(({ email, orderNumber }) =>
        this.ordersService.removeUserFromOrder(orderNumber, email).pipe(
          map(() =>
            OrderOverviewActions.RemoveUserActions.removeSuccess({ email })
          ),
          catchError((err: Error) =>
            of(
              OrderOverviewActions.RemoveUserActions.removeFailure({
                error: err.message,
              })
            )
          )
        )
      )
    );
  });

  removeUserSuccessAlert$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderOverviewActions.RemoveUserActions.removeSuccess),
        tap(({ email }) =>
          this.toastService.open({
            type: 'info',
            text: this.translate.translate(
              'removeUserToastSuccess.txt',
              { email },
              'orderOverview'
            ),
            position: { bottom: 128, right: 32 },
          })
        ),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  removeUserFailureAlert$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderOverviewActions.RemoveUserActions.removeFailure),
        tap(({ error }) =>
          this.toastService.open({
            // TODO - toasts don't have an error style, use info for now
            type: 'info',
            // this error message comes from the API is not translated
            text: error,
            position: { bottom: 128, right: 32 },
          })
        ),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  /**
   * This effect checks that a given order is not on hold before triggering a download
   * from the browser. The added logic for creating an <a> element on the fly and programmatically
   * clicking it ensures that the browser downloads the ZIP file instead of trying to open the
   * file in a new tab.
   */
  downloadAssets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.DownloadAssetsActions.downloadRequested),
      concatLatestFrom(() =>
        this.store.select(OrderOverviewSelectors.getOrderOverviewState)
      ),
      filter(([_, { onHold }]) => onHold === false),
      switchMap(([{ href, assetTypes }]) =>
        this.ordersService.downloadAssetBundle(href, assetTypes).pipe(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          map((res: any) => {
            const contentDisp = res.headers.get(
              'content-disposition'
            ) as string;
            const filename: string =
              this.filenameFromContentDisposition(contentDisp);
            const contentType = res.headers.get('content-type');
            const blob = new Blob([res.body], {
              type: contentType,
            });
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            // console.dir(link);
            link.click();
            link.remove();
            return OrderOverviewActions.DownloadAssetsActions.downloadSuccessful();
          }),
          catchError((error: Error) => {
            this.toastService.open({
              text: this.translate.translate(
                'assetDownloadToastFailure.txt',
                { message: error.message },
                'orderOverview'
              ),
              position: { bottom: 128, right: 32 },
            });
            return of(
              OrderOverviewActions.DownloadAssetsActions.downloadFailure({
                error: error.message,
              })
            );
          })
        )
      )
    );
  });

  stopDownloadForAssetsOnHold$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrderOverviewActions.DownloadAssetsActions.downloadRequested),
        concatLatestFrom(() =>
          this.store.select(OrderOverviewSelectors.getOrderOnHold)
        ),
        filter(([_, onHold]) => onHold === true),
        tap(() => {
          this.dialogService.open(this.config.unableToDownloadComponent);
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  updateUserPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrderOverviewActions.ChangeUserPermissionActions.changePermission),
      concatLatestFrom(() =>
        this.store
          .select(OrderOverviewSelectors.getOrderDetails)
          .pipe(map((order) => order?.number as string))
      ),
      tap(([{ email }]) => {
        this.toastService.open({
          text: this.translate.translate(
            'updateUserToastStart.txt',
            { email },
            'orderOverview'
          ),
          animation: { fadeIn: 250, fadeOut: 500 },
        });
      }),
      switchMap(([{ label, email, role }, number]) =>
        this.ordersService
          .updateUserRoleInOrder(number, email, { label, role })
          .pipe(
            map(() => {
              this.toastService.open({
                text: this.translate.translate(
                  'updateUserToastSuccess.txt',
                  { email },
                  'orderOverview'
                ),
              });
              return OrderOverviewActions.ChangeUserPermissionActions.changeSuccess(
                {
                  label,
                  email,
                  role,
                }
              );
            }),
            catchError((err: Error) => {
              this.toastService.open({
                text: this.translate.translate(
                  'updateUserToastFailure.txt',
                  { message: err.message },
                  'orderOverview'
                ),
              });
              return of(
                OrderOverviewActions.ChangeUserPermissionActions.changeFailure({
                  error: err.message,
                })
              );
            })
          )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private ordersService: OrdersService,
    private dialogService: DialogService,
    private store: Store,
    @Inject(DATA_ACCESS_ORDERS_CONFIG) private config: DataAccessOrdersConfig
  ) {}

  private filenameFromContentDisposition(s: string): string {
    if (s == null) {
      return 'download.zip';
    }
    const parts = s.split('filename=');
    const filename = parts[parts.length - 1];
    return filename;
  }
}
