import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { environment } from '@mysas/shared/util-environment';

@Component({
  selector: 'chat',
  template: ``,
})
export class ChatComponent implements OnInit, AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    //
  }

  initializeCsm() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.text = `
      SN_CSM_EC.init({
        moduleID: '${environment.csmChat.globalModuleId}',
        loadFeature: SN_CSM_EC.loadEMFeature(),
      });
      SN_CSM_EC.onLogin();
    `;
    document.head.append(s);
  }

  intializeOkta() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.text = `
    var authClient = new OktaAuth({
        issuer: '${environment.csmChat.oktaIssuer}',
        clientId: '${environment.csmChat.oktaProfileClientId}',
        redirectUri: window.location.href
    });
    
    authClient.session.exists().then(
      function (exists) {
        if (exists) {
          SN_CSM_EC.onLogin();
        }
      }
    );
    `;
    document.head.append(s);
  }

  ngAfterViewInit() {
    // <script> tags are stripped from templates, so it has to be added dynamically
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = environment.csmChat.scriptRef;
    // have to do an onload so that the script is brought in before doing initialization
    s.onload = this.initializeCsm.bind(this);
    document.head.appendChild(s);

    const o = document.createElement('script');
    o.type = 'text/javascript';
    o.src = environment.csmChat.oktaScriptRef;
    // have to do an onload so that the script is brought in before doing initialization
    o.onload = this.intializeOkta.bind(this);
    document.head.appendChild(o);
  }
}
