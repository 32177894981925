import { environment } from '@mysas/shared/util-environment';
import { TranslocoConfig } from '@ngneat/transloco';
import { TranslocoLocaleConfig } from '@ngneat/transloco-locale';

export const l10nConfig: TranslocoLocaleConfig = {
  defaultLocale: 'en-US',
  langToLocaleMapping: {
    en: 'en-US',
    de: 'de-DE',
    es: 'es-ES',
    fr: 'fr-FR',
    it: 'it-IT',
    ja: 'ja-JA',
    ko: 'ko-KO',
    pl: 'pl-PL',
    th: 'th-TH',
    zh: 'zh-Hans',
    'zh-Hans': 'zh-Hans',
    'zh-SG': 'zh-Hans',
    'zh-CN': 'zh-Hans',
    'zh-Hant': 'zh-Hant',
    'zh-TW': 'zh-Hant',
    'zh-HK': 'zh-Hant',
    'zh-MO': 'zh-Hant',
  },
};

export const i18nConfig: TranslocoConfig = {
  prodMode: environment.production,
  reRenderOnLangChange: true,
  availableLangs: [
    { id: 'en', label: 'English' },
    { id: 'de', label: 'Deutsch' },
    { id: 'es', label: 'Español' },
    { id: 'fr', label: 'Français' },
    { id: 'it', label: 'Italiano' },
    { id: 'ja', label: '日本語' },
    { id: 'ko', label: '한국어' },
    { id: 'pl', label: 'polski' },
    { id: 'th', label: 'ไทย' },
    { id: 'zh-Hans', label: '中文 ( 简体 )' },
    { id: 'zh-Hant', label: '中文 ( 繁體 )' },
  ],
  defaultLang: 'en',
  fallbackLang: 'en',
  missingHandler: {
    useFallbackTranslation: true,
  },
};
