import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export const userFeatureKey = 'user';

export interface State {
  name?: string;
  email?: string;
  preferred_username?: string;
  vspTLA?: string | null;
}

export const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(UserActions.clearOktaUser, (): State => ({ ...initialState })),
  on(UserActions.OktaActions.store, (state, { authState }): State => {
    return {
      ...state,
      name: authState.idToken?.claims.name,
      email: authState.idToken?.claims.email,
      preferred_username: authState.idToken?.claims.preferred_username,
      vspTLA: (authState.accessToken?.claims['vspTLA'] as string) || null,
    };
  })
);
