/* eslint-disable no-restricted-syntax */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retryStrategy } from '@mysas/shared/util-operators';
import {
    Translation,
    TranslocoLoader
} from '@ngneat/transloco';
import { Observable, firstValueFrom, from, map, of } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { PropertiesFile } from './properties-parser';
import {
    combinePromises,
    getLanguageFileNames
} from './scoped-translations';

const maxRetryAttempts = 3;

@Injectable({ providedIn: 'root' })
export class TranslocoHttpPropertyLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(locale: string): Observable<Translation> {
    const langPromises: Promise<Translation | undefined>[] = [];

    getLanguageFileNames(locale).forEach((filename) => {
      // list of promises
      console.log('I1: ', filename);
      langPromises.push(
        // convert the obs to a promise
        firstValueFrom(
          // get the properties file as an obs or an obs of undefined
          this.http
            .get(`/assets/i18n/${filename}`, { responseType: 'text' })
            .pipe(
              retryWhen(
                retryStrategy({
                  maxRetryAttempts: maxRetryAttempts,
                })
              ),
              map((data: string) => {
                const p = new PropertiesFile(data);
                return p.toObject();
              }),
              catchError(() => {
                // console.warn(`[locale]: ${filename}: does not exist`);
                return of(undefined);
              })
            )
        )
      );
    });
    // return the properties files combined and wrapped in an observable
    console.log('LP: ', combinePromises(langPromises));
    return from(combinePromises(langPromises));
  }
}