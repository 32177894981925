import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  NgModule,
  OnInit,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '@mysas/shared/util-environment';
import {
  getLocalizedLink,
  langIdToLocale,
  parseLocale,
} from '@mysas/shared/util-i18n';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftNavComponent implements OnInit {
  private readonly langService = inject(TranslocoService);

  lang = this.langService.getActiveLang();

  // the language needs to be observed
  portalHelpLink = getLocalizedLink(
    environment.links['portalHelpLink'],
    parseLocale(langIdToLocale(this.lang))
  );

  myCasesLink = environment.links['csmMyCases'].url;
  createCaseLink = environment.links['csmCreateCase'].url;

  ngOnInit(): void {
    this.langService.langChanges$.subscribe((lang) => {
      this.portalHelpLink = getLocalizedLink(
        environment.links['portalHelpLink'],
        parseLocale(langIdToLocale(lang))
      );
    });
  }
}

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule],
  declarations: [LeftNavComponent],
  exports: [LeftNavComponent, RouterModule],
})
export class LeftNavComponentModule {}
