import { IPreference } from '@mysas/shared/data-access-orders';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const LoadPreferences = createActionGroup({
  source: 'Load Prefs',
  events: {
    load: emptyProps(),
    success: props<{ preferences: IPreference[] }>(),
    failure: props<{ error: string }>(),
  },
});

export const UpdatePreferences = createActionGroup({
  source: 'Update Prefs',
  events: {
    update: props<{ preference: IPreference }>(),
    success: props<{ preference: IPreference }>(),
    failure: props<{ error: string }>(),
  },
});
