import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  DataAccessOrdersModule,
  DATA_ACCESS_ORDERS_CONFIG,
} from '@mysas/portal/orders/data-access-orders';
import { UnableToDownloadComponent } from '@mysas/portal/orders/ui-order-overview';
import { FooterComponentModule } from '@mysas/portal/shared/ui-layout';
import { OktaAuthGuard } from '@okta/okta-angular';
import { LayoutComponent } from './layout/layout.component';

@NgModule({
  imports: [
    CommonModule,

    RouterModule.forChild([
      {
        path: 'orders',
        component: LayoutComponent,
        canActivateChild: [OktaAuthGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@mysas/portal/orders/feature-list-orders').then(
                (m) => m.FeatureListModule
              ),
          },
          {
            path: ':orderId',
            loadChildren: () =>
              import('@mysas/portal/orders/feature-order-overview').then(
                (m) => m.FeatureOrderModule
              ),
          },
        ],
      },
    ]),
    FooterComponentModule,
    DataAccessOrdersModule,
  ],
  providers: [
    {
      provide: DATA_ACCESS_ORDERS_CONFIG,
      useFactory: () => ({
        unableToDownloadComponent: UnableToDownloadComponent,
      }),
    },
  ],
  declarations: [LayoutComponent],
})
export class PortalOrdersShellModule {}
