import { ComponentType } from '@angular/cdk/portal';
import { ToastType } from '@mysas/portal/shared/data-access-toaster';
import {
  IAddUserToOrder,
  IAsset,
  IInviteUserToOrder,
  IOrderAvailableCadence,
  IOrderDetail,
  IOrderUser,
} from '@mysas/shared/data-access-orders';
import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';

export const initOrderOverview = createAction('[OrderOverview Page] Init');

export const LoadOrderDetail = createActionGroup({
  source: 'Order Details',
  events: {
    load: props<{ orderNum: string }>(),
    success: props<{ detail: IOrderDetail }>(),
    failure: props<{ error: string }>(),
  },
});

export const LoadOrderUsers = createActionGroup({
  source: 'Order Users',
  events: {
    load: props<{ orderNum: string }>(),
    success: props<{ users: IOrderUser[] }>(),
    failure: props<{ error: string }>(),
  },
});

/** Order Cadence Actions  */
export const LoadOrderCadences = createActionGroup({
  source: 'Order Cadences',
  events: {
    load: props<{ orderNum: string }>(),
    success: props<{ availableCadences: IOrderAvailableCadence[] }>(),
    failure: props<{ error: string }>(),
  },
});

export const LoadOrderDownloadHistory = createActionGroup({
  source: 'Order Download History',
  events: {
    load: props<{ orderNum: string }>(),
    success: props<{ downloadHistory: IAsset[] }>(),
    failure: props<{ error: string }>(),
  },
});
// TODO remove?

// export const loadOrderOverviewSuccess = createAction(
//   '[OrderOverview/API] Load OrderOverview Success',
//   props<{ order: IOrder }>()
// );

// export const loadOrderOverviewFailure = createAction(
//   '[OrderOverview/API] Load OrderOverview Failure',
//   props<{ error: string }>()
// );

// export const loadOrderDetailSuccess = createAction(
//   '[OrderOverview/API] Load Order Detail Success',
//   props<{ detail: IOrderDetail }>()
// );

// export const loadOrderDetailFailure = createAction(
//   '[OrderOverview/API] Load Order Detail Failure',
//   props<{ error: string }>()
// );

// export const loadOrderUsersSuccess = createAction(
//   '[OrderOverview/API] Load Order Users Success',
//   props<{ users: IOrderUser[] }>()
// );

// export const loadOrderUsersFailure = createAction(
//   '[OrderOverview/API] Load Order Users Failure',
//   props<{ error: string }>()
// );

/** Order Access-Related Actions */

export const openAddUserDialog = createAction(
  '[OrderOverview] Opened Add User Dialog',
  props<{ component: ComponentType<unknown> }>()
);

export const closeAddUserDialog = createAction(
  '[OrderOverview] Closed Add User Dialog',
  props<{ toastType: ToastType; text: string }>()
);

export const addUser = createAction(
  '[OrderOverview] Add User To Order',
  props<{ payload: IAddUserToOrder; orderNumber: string }>()
);

export const addUserSuccess = createAction(
  '[OrderOverview] Invite Success',
  props<{ user: Omit<IOrderUser, 'type'> }>()
);

export const addUserFailure = createAction(
  '[OrderOverview] Invite Failure',
  props<{ error: string }>()
);

export const addUserReset = createAction('[OrderOverview] Reset Dialog');

export const ChangeUserPermissionActions = createActionGroup({
  source: 'User Permission',
  events: {
    'Change Permission': props<{
      label: string;
      email: string;
      role: 'user' | 'owner';
    }>(),
    'Change Success': props<{
      label: string;
      email: string;
      role: 'user' | 'owner';
    }>(),
    'Change Failure': props<{ error: string }>(),
  },
});

export const ResendInviteActions = createActionGroup({
  source: 'Resend Invite',
  events: {
    'Invite Requested': props<{
      orderNumber: string;
      payload: IInviteUserToOrder;
    }>(),
    'Invite Success': props<{ email: string }>(),
    'Invite Failure': props<{ error: string }>(),
  },
});

export const RemoveUserActions = createActionGroup({
  source: 'Remove User',
  events: {
    'Remove Requested': props<{ email: string; orderNumber: string }>(),
    // Success should trigger toast with success message
    'Remove Success': props<{ email: string }>(),
    // failure should also trigger a toast with the error message
    'Remove Failure': props<{ error: string }>(),
  },
});

export const DownloadAssetsActions = createActionGroup({
  source: 'Download Assets',
  events: {
    'Download Requested': props<{
      // uriName should match the property of the same name in an Order Object
      uriName: string;
      // uriName should match the property of the same name in an Order Object
      uriVersion: string;
      href: string;
      assetTypes: {
        certificates: boolean;
        license: boolean;
        deploymentAssets: boolean;
      };
    }>(),
    'Download Successful': emptyProps(),
    'Download Failure': props<{ error: string }>(),
  },
});

export const ChangeFilter = createActionGroup({
  source: 'Download Filter',
  events: {
    'Select Cadence': props<{ selectedCadence: string | null }>(),
    'Select Version': props<{ selectedVersion: string | null }>(),
    'Select Asset Type': props<{ selectedAssetType: string | null }>(),
    'Update Filter': props<{ filter: string | null }>(),
    'Clear Filters': emptyProps(),
  },
});
