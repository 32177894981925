<ng-container *transloco="let t">
  <div class="banner">
    <div class="banner__branding">
      <a tabindex="0" href="https://www.sas.com" target="_blank">
        <img
          src="/assets/images/sas-logo-white.svg"
          class="banner__branding-img"
          alt="{{ t('bannerSasLogo.label') }}"
        />
      </a>
      <span class="banner__branding-title">{{ bannerText$ | async }}</span>
    </div>

    <div class="banner__actions">
      <div class="banner__actions-container">
        <a
          id="link-skip-to-main-content"
          style="display: none"
          class="skip-main"
          [routerLink]="[currentUrl]"
          [fragment]="skipLinkPath"
          >{{ t('skipToMainContent.link') }}
        </a>

        <button
          tabindex="0"
          class="btn btn-standard-no-bd-bg"
          role="button"
          (click)="openNotificationDialog()"
          *ngIf="notifications$ | async as notifications"
          attr.aria-label="{{ t('bannerNotificationsMenu.aria') }}"
        >
          <svg
            localeOverrides="{}"
            xmlns="http://www.w3.org/2000/svg"
            class="Notification has-badge"
            width="14"
            height="14"
            version="1.1"
            viewBox="0 0 14 14"
            xml:space="preserve"
            attr.aria-label="{{ t('bannerUserProfileMenuIcon.aria') }}"
          >
            <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
            <path
              class="iconColor"
              d="M5,11h4c0,1.105-0.895,2-2,2S5,12.105,5,11z M13,8v2H1V8c0.947,0,1.714-1.053,1.714-2V5.286 C2.714,2.929,4.643,1,7,1s4.286,1.929,4.286,4.286V6C11.286,6.947,12.053,8,13,8z M12,8.779C10.955,8.296,10.286,7.1,10.286,6V5.286 C10.286,3.475,8.812,2,7,2S3.714,3.475,3.714,5.286V6c0,1.1-0.669,2.296-1.714,2.779V9h10V8.779z"
            ></path>
          </svg>
          <div
            class="notification-badge"
            *ngIf="notifications.length"
            [attr.aria-label]="t('bannerNotificationsBtnAlert.aria')"
          ></div>
        </button>
      </div>

      <div class="banner__actions-container">
        <button
          id="user-menu-button"
          tabindex="0"
          class="btn btn-standard-no-bd-bg"
          role="button"
          [dropdownTriggerFor]="userMenu"
          (click)="focusUserMenu()"
          attr.aria-label="{{ t('bannerUserProfileMenu.aria') }}"
          aria-haspopup="menu"
        >
          <svg
            localeOverrides="{}"
            xmlns="http://www.w3.org/2000/svg"
            class="GrooveUserManagement"
            width="16"
            height="16"
            version="1.1"
            viewBox="0 0 16 16"
            xml:space="preserve"
            attr.aria-label="{{ t('bannerUserProfileMenuIcon.aria') }}"
          >
            <path class="svgToolTipPane" fill="none" d="M0 0H16V16H0z"></path>
            <path
              class="iconColor"
              d="M8,0C6.343,0,5,1.791,5,4c0,2.209,1.343,4,3,4s3-1.791,3-4C11,1.791,9.657,0,8,0z M8,7C6.916,7,6,5.626,6,4 s0.916-3,2-3s2,1.374,2,3S9.084,7,8,7z M10.637,8.116C9.875,8.675,8.976,9,8,9S6.124,8.675,5.362,8.116 c-2.117,0.463-3.611,2.314-3.328,4.38L2.378,15h11.244l0.343-2.504C14.249,10.43,12.755,8.579,10.637,8.116z M12.975,12.36L12.75,14 h-9.5l-0.225-1.64c-0.189-1.379,0.722-2.678,2.161-3.16C6.048,9.725,7.011,10,8,10s1.952-0.275,2.813-0.799 C12.252,9.683,13.164,10.981,12.975,12.36z"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <dropdown #userMenu>
    <ul class="menu">
      <li
        class="menu-item menu-item--multiline menu-item--noop"
        *ngIf="user$ | async as user"
      >
        <span class="menu-item__title">{{ user.name }}</span>
        <span class="menu-item__description">{{ user.email }}</span>
      </li>
      <li class="menu-divider"></li>
      <li class="menu-item" tabindex="0">
        <a
          #firstLinkUserMenu
          id="first-link-user-menu"
          href="https://www.sas.com/profile/user/contact.htm"
          style="color: var(--color-text-default)"
          target="_blank"
          attr.aria-label="{{ t('bannerEditProfile.aria') }}"
          >{{ t('bannerEditProfile.label') }}</a
        >
      </li>
      <li tabindex="0" class="menu-item" (click)="openPrefsDialog()">
        {{ t('bannerEmailPrefs.label') }}
      </li>
      <li class="menu-item">
        <a
          href="mailto:mysas@sas.com"
          class="link"
          style="display: block; color: var(--color-text-default)"
          >{{ t('bannerContactSasSupport.link') }}</a
        >
      </li>
      <li tabindex="0" class="menu-item" (click)="logout()">
        {{ t('bannerLogout.label') }}
      </li>
    </ul>
  </dropdown>

  <dropdown #notificationsMenu>
    <ul class="menu" *ngIf="notifications$ | async as notifications">
      <li tabindex="0" class="menu-item" *ngFor="let n of notifications">
        {{ n.title }}
      </li>
    </ul>
  </dropdown>
</ng-container>
