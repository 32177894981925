/**
 * Pulled from https://christianlydemann.com/accessing-nested-router-params-in-angular-with-ngrx-router-store/
 */

import { ActivatedRouteSnapshot, Params } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface State {
  router: fromRouter.RouterReducerState;
}

export const selectRouter =
  createFeatureSelector<fromRouter.RouterReducerState>('router');

// export const {
//   selectQueryParams: selectQueryParamsNgRx,
//   selectQueryParam,
//   selectRouteData,
//   selectUrl,
// } = fromRouter.getSelectors(selectRouter);

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams: selectQueryParamsNgrx, // select the current route query params
  selectQueryParam: selectQueryParamNgrx, // factory function to select a query param
  selectRouteParams: selectRouteParamsNgrx, // select the current route params
  selectRouteParam: selectRouteParamNgrx, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
  selectTitle, // Select the title if available
} = fromRouter.getRouterSelectors();

const getRouteParams = (route: ActivatedRouteSnapshot): Params => {
  if (route.children.length === 0) {
    return route.params;
  }

  const combinedChildParams = route.children.reduce(
    (prev: Params, childRoute) => ({ ...prev, ...getRouteParams(childRoute) }),
    {}
  );
  return {
    ...route.params,
    ...combinedChildParams,
  };
};

export const selectRouteParams = createSelector(selectRouter, (routerState) => {
  /* istanbul ignore next */
  if (!routerState?.state?.root) {
    return {};
  }

  return getRouteParams(routerState.state.root);
});

export const selectRouteParam = (routeParam: string) =>
  createSelector(selectRouteParams, (routeParams) => {
    return routeParams[routeParam];
  });

export const selectQueryParams = createSelector(
  selectQueryParamsNgrx,
  (params) => params || {}
);
