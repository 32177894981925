import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private bannerText$$ = new BehaviorSubject<string>('MySAS');
  bannerText$ = this.bannerText$$.asObservable();

  private title = inject(Title);

  updateBannerText(text: string, ignoreWindowTitle?: boolean) {
    this.bannerText$$.next(text);
    if (!ignoreWindowTitle) {
      this.title.setTitle(text);
    }
  }
}
