import { IOrder } from './interfaces/orders/order.interface';

export type SortDirectionType = 'ascending' | 'descending';

export type SortByType = 'site' | 'order' | 'date' | 'updates';

/**
 * Util function for sorting an array of orders when using the `.sort()` method.
 *
 * @example
 * ```
 * orderDetailList.sort((a,b) => sortOrders(a, b, 'site', 'descending'))
 * ```
 *
 * @param {IOrderDetail} a
 * @param {IOrderDetail} b
 * @param {SortByType} sortBy
 * @param {SortDirectionType} sortDir
 * @returns {number}
 */
export const sortOrders = (
  a: IOrder,
  b: IOrder,
  sortBy: SortByType,
  sortDir: SortDirectionType
): number => {
  let rv: number;
  switch (sortBy) {
    case 'date':
      rv = Date.parse(a.date) - Date.parse(b.date);
      break;
    case 'order':
      rv = a.number.localeCompare(b.number);
      break;
    case 'site':
      rv = a.siteNum.localeCompare(b.siteNum);
      break;
    case 'updates':
      rv = a.updateStatus.localeCompare(b.updateStatus);
      break;
  }
  if (sortDir === 'descending') {
    return -1 * rv;
  }
  return rv;
};
