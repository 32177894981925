import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable, Injector } from '@angular/core';
import { ToastRef } from './toast-ref';
import {
  defaultToastConfig,
  IToastConfig,
  TOAST_COMPONENT,
  TOAST_CONFIG,
} from './toast.interface';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private lastToast: ToastRef | undefined;
  private toastTracker = 0;

  constructor(
    @Inject(TOAST_COMPONENT) readonly component: ComponentType<unknown>,
    private overlay: Overlay,
    private injector: Injector
  ) {}

  /**
   * In it's current state, this method will launch a toaster notification in the bottom right
   * of the viewport.
   *
   * @todo positioning for multiple alerts is.. iffy. Method appears to work fine for 1-2 toasts
   *
   * @param {?IToastConfig} [_config]
   * @returns {*}
   */
  open(_config?: Partial<IToastConfig>): ToastRef {
    const config = Object.assign({}, { ...defaultToastConfig }, _config);
    const positionStrategy = this.getPositionStrategy(config);

    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: false,
      panelClass: 'toast',
    });
    /* istanbul ignore next */
    overlayRef.overlayElement.parentElement?.classList.add('toast-container');
    // classList.add('testing');

    const toastRef = new ToastRef(overlayRef);

    this.lastToast = toastRef;

    this.toastTracker += 1;
    toastRef.afterClosed().subscribe(() => {
      this.toastTracker -= 1;
    });

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: ToastRef, useValue: toastRef },
        { provide: TOAST_CONFIG, useValue: config },
      ],
    });

    const portal = new ComponentPortal(this.component, null, injector);
    const componentRef = overlayRef.attach(portal);
    (toastRef as { componentInstance: unknown }).componentInstance =
      componentRef.instance;

    return toastRef;
  }

  getPositionStrategy(config: IToastConfig) {
    return this.overlay
      .position()
      .global()
      .bottom(this.getPosition(config))
      .right(`${config.position?.right ?? 32}px`);
  }

  getPosition(config: IToastConfig) {
    const lastToastIsVisible = this.lastToast?.isVisible() ?? false;
    if (lastToastIsVisible && this.lastToast) {
      const rect = this.lastToast.getPosition();
      const newBottom = 32 + this.toastTracker * rect.height;
      return `${newBottom}px`;
    }
    return `${config.position?.bottom ?? 32}px`;
  }
}
