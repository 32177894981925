import { inject, InjectionToken } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { map, Observable } from 'rxjs';

export type UserLoggedInProvider = Observable<boolean>;

/**
 * This token allows any component, anywhere in the application, to subscribe to the single
 * observable that stores whether a user is logged in. The AuthService as a whole does not need
 * to be imported or injected, this can be used instead
 *
 * @example typescript
 * ```
  constructor(@Inject(USER_LOGGED_IN) public isLoggedIn$: UserLoggedInProvider) {}
 * ```
 * @type InjectionToken
 */
export const USER_LOGGED_IN = new InjectionToken(
  'Subscribe to an observable that reports whether the user is logged in',
  {
    factory: () => {
      const oktaAuth = inject(OktaAuthStateService);
      return oktaAuth.authState$.pipe(map((s) => s.isAuthenticated));
    },
  }
);
