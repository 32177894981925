import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { AssetType } from '@mysas/shared/data-access-orders';

const ASSET_TYPE_NAME_MAP: Record<AssetType, string> = {
  certificates: 'Certificates',
  deploymentAssets: 'Deployment Assets',
  deploymentReadme: 'Deployment Readme',
  license: 'License',
  multipleAssets: 'Multiple Assets',
};

@Pipe({
  name: 'assetType',
})
export class AssetTypePipe implements PipeTransform {
  transform(value: AssetType, ...args: unknown[]): string {
    return ASSET_TYPE_NAME_MAP[value];
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [AssetTypePipe],
  exports: [AssetTypePipe],
})
export class AssetTypePipeModule {}
