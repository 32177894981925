import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { AssetDownloadService } from '@mysas/portal/orders/data-access-orders';
import { ToasterService } from '@mysas/portal/shared/data-access-toaster';
import { StateLoadingStatusType } from '@mysas/portal/shared/util-state';
import { IAsset } from '@mysas/shared/data-access-orders';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, finalize, share, tap } from 'rxjs';
import { AssetTypePipeModule } from '../pipes';
@Component({
  selector: 'mysas-download-history-table',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    TranslocoLocaleModule,
    AssetTypePipeModule,
  ],
  templateUrl: './download-history-table.component.html',
  styleUrls: ['./download-history-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadHistoryTableComponent {
  @Input() downloads: IAsset[] | null = null;

  @Input() loadingState: StateLoadingStatusType = 'NOT_LOADED';

  @Input() error: string | undefined;

  downloadBtnDisabled$ = new BehaviorSubject<boolean>(false);

  private toastService = inject(ToasterService);
  private assetDownloader = inject(AssetDownloadService);
  private translate = inject(TranslocoService);

  logger = inject(NGXLogger);

  ariaCadenceVersion(name: string, version: string): string {
    if (name || version) {
      return `${name} ${version}`;
    } else {
      return this.translate.translate('noCadenceVersion.aria');
    }
  }

  /**
   * Method for triggering a download of a specific asset.
   *
   * IMPORTANT!! Without the `share()` operator, clicking the Download button
   * will trigger a download for *every* record in the history table (since each button
   * binds to the BehaviorSubject with an async pipe).
   */
  downloadHistoricAsset(asset: IAsset) {
    this.downloadBtnDisabled$.next(true);
    this.assetDownloader
      .downloadAssetWithProgress(asset)
      .pipe(
        tap((state) => this.logger.debug(state)),
        finalize(() => this.downloadBtnDisabled$.next(false)),
        share()
      )
      .subscribe({
        error: (err: HttpErrorResponse) => {
          console.error(err);
          this.toastService.open({
            text: this.translate.translate(
              'assetDownloadToastFailure.txt',
              { message: err.message },
              'orderOverview'
            ),
            position: { bottom: 128, right: 32 },
          });
        },
      });
  }
}
