export interface OktaConfigMap {
  dev: string;
  test: string;
  staging: string;
  prod: string;
}

export const OKTA_CLIENT_ID_MAP: OktaConfigMap = {
  // same as test since the dev Okta environment is unstable
  dev: '0oa120w9q4tXF0nOT0h8',
  test: '0oa11o1vvwsBYP55W0h8',
  staging: '0oa11o1vvwsBYP55W0h8',
  prod: '0oactynrohvuzOF3F2p7',
};

export const OKTA_ISSUER_URL_MAP: OktaConfigMap = {
  // This replaces teh auth-dev.sas.com URL since the auth-dev environment is unstable and used for internal
  // auth testing
  dev: 'https://auth-dev.sas.com/oauth2/ausxwfj7zxK6jCLs90h7',

  // 'test' and 'staging' do not use the https://stagesas-admin.* URLs because the Okta SDK detects "admin" in the
  // URL and refuses to initialize in non-dev environments
  test: 'https://auth-test.sas.com/oauth2/ausy0okbbpyUOQqyd0h7',
  staging: 'https://auth-test.sas.com/oauth2/ausy0okbbpyUOQqyd0h7',
  prod: 'https://auth.sas.com/oauth2/ausahp1sb9nUNn5Cq2p7',
};

export const OKTA_AUDIENCE_MAP: OktaConfigMap = {
  dev: 'https://auth-dev.sas.com',
  test: 'https://auth-test.sas.com',
  staging: 'https://auth-test.sas.com',
  prod: 'https://auth.sas.com',
};

// https://sas-admin.okta.com/admin/app/oidc_client/instance/0oactynrohvuzOF3F2p7#tab-general
// https://sas-admin.okta.com/admin/app/oidc_client/instance/0oad3s3lpjv8x27l42p7/#tab-general
