import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OktaAuthStateService } from '@okta/okta-angular';
import { filter, map } from 'rxjs/operators';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  /**
   * This was split out for easier testing. It's sole purpose is to respond
   * to state changes in the OktaAuthStateService
   */
  watchForAuthStateChange$ = createEffect(() => {
    return this.authStateService.authState$.pipe(
      filter(({ isAuthenticated }) => !!isAuthenticated),
      map((authState) => UserActions.OktaActions.trigger({ authState }))
    );
  });

  /**
   * This effect is detected by the reducer and used for copying data from
   * the auth state into the NgRx state
   */
  storeUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.OktaActions.trigger),
      map(({ authState }) => UserActions.OktaActions.store({ authState }))
    );
  });

  constructor(
    private actions$: Actions,
    private authStateService: OktaAuthStateService
  ) {}
}
