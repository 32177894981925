import { HttpClient, HttpEventType } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { IAsset } from '@mysas/shared/data-access-orders';
import { NGXLogger } from 'ngx-logger';
import { Observable, tap } from 'rxjs';
import { download, IAssetFileDownloadState } from '../asset-download.interface';
import { SAVER, Saver } from './saver.provider';

/**
 * This service is separate from all other API services, as it's meant to be injected into
 * "dumb"/presentational components when those components are allowing a user to download an
 * asset from a non-APIM URL (such as API gateway).
 *
 * Initially pulled from https://nils-mehlhorn.de/posts/angular-file-download-progress and modified
 * to support Orders-specific needs.
 */
@Injectable({
  providedIn: 'root',
})
export class AssetDownloadService {
  logger = inject(NGXLogger);
  constructor(private http: HttpClient, @Inject(SAVER) private save: Saver) {}

  downloadAssetWithProgress(
    asset: IAsset
  ): Observable<IAssetFileDownloadState> {
    this.logger.debug(`[AssetDownloader] Downloading asset: ${asset.name}`);
    let url = asset.link.href;
    if (!url.includes('okta')) {
      // updating this to account for LTC-specific asset downloading
      url = url.replace(/\/(reofs[\w-]*)\//, '/$1-okta/');
    }
    return this.http
      .get(url, {
        reportProgress: true,
        responseType: 'blob',
        observe: 'events',
      })
      .pipe(download((blob) => this.save(blob, asset.name)));
  }

  downloadFromUrl(url: string): Observable<IAssetFileDownloadState> {
    this.logger.debug(`[AssetDownloader] Downloading asset: ${url}`);
    // let url = asset.link.href;
    if (!url.includes('okta')) {
      url = url.replace(/\/(reofs[\w-]*)\//, '/$1-okta/');
    }
    return this.http
      .get(url, {
        reportProgress: true,
        responseType: 'blob',
        observe: 'events',
      })
      .pipe(
        tap((resp) => {
          if (resp.type === HttpEventType.Response) {
            console.log(`resp headers: ${resp.headers.keys()}`);
          }
        }),
        download((blob) => this.save(blob, 'asset')),
        tap((state) => console.log(`download state`, state))
      );
  }
}
