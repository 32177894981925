import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponentModule } from '@mysas/portal/shared/ui-layout';
import { OktaAuthGuard } from '@okta/okta-angular';
import { LayoutComponent } from './layout/layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: LayoutComponent,
        canActivateChild: [OktaAuthGuard],
        loadChildren: () =>
          import('@mysas/portal/dashboard/feature').then(
            (m) => m.FeatureModule
          ),
      },
    ]),
    FooterComponentModule,
  ],
  declarations: [LayoutComponent],
})
export class PortalDashboardShellModule {}
