import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OktaAuthStateService } from '@okta/okta-angular';
import { map } from 'rxjs';
import * as UserSelectors from './state/user.selectors';

@Injectable()
export class UserFacade {
  userName$ = this.store.select(UserSelectors.selectUserName);
  userEmail$ = this.store.select(UserSelectors.selectUserEmail);
  userPreferredUsername$ = this.store.select(
    UserSelectors.selectUserPreferredUsername
  );
  loggedIn$ = this.authStateService.authState$.pipe(
    map((authState) => !!authState.isAuthenticated)
  );
  vspTLA$ = this.store.select(UserSelectors.selectVspTLA);

  constructor(
    private store: Store,
    private authStateService: OktaAuthStateService
  ) {}
}
