<div class="token token-{{ status }}">
  <div class="token-content">
    <ng-content></ng-content>
    <svg
      *ngIf="dismissable"
      localeOverrides="{}"
      xmlns="http://www.w3.org/2000/svg"
      class="CloseTab dismissable"
      width="14"
      height="14"
      version="1.1"
      viewBox="0 0 14 14"
      xml:space="preserve"
    >
      <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
      <path
        class="iconColor"
        d="M7.707,7L10,9.293L9.293,10L7,7.707L4.707,10L4,9.293L6.293,7L4,4.707L4.707,4L7,6.293L9.293,4L10,4.707 L7.707,7z"
      ></path>
    </svg>
  </div>
</div>
