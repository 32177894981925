import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgModule,
  Renderer2,
  ViewChild,
} from '@angular/core';

import {
  combinePromises,
  getLanguageDefinitions,
  getLanguageFileNames,
  langIdToLocale,
  scopeLoader,
} from '@mysas/shared/util-i18n';
import {
  LangDefinition,
  TRANSLOCO_SCOPE,
  Translation,
  TranslocoModule,
  TranslocoService,
} from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { DropdownModule } from '../dropdown/dropdown-trigger-for.directive';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements AfterViewInit {
  @ViewChild('languageDropdown') languageDropdown!: ElementRef<HTMLElement>;

  /**
   * the current value in the language dropdown
   */
  currentLanguage = 'xxx';
  availableLanguages: LangDefinition[] = getLanguageDefinitions();
  constructor(
    private langService: TranslocoService,
    private localeService: TranslocoLocaleService,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    // const prefix = '[locale/footer#constructor]: ';
    // called by transloco when setActiveLang is called
    this.langService.langChanges$.subscribe((lang) => {
      // console.log(`${prefix} langChanges event - updating dropdown: ${lang}`);
      this.setCurrentLanguage(lang);
    });
  }

  onClickLanguageDropdownButton(): void {
    this.languageDropdown.nativeElement.focus();
  }

  ngAfterViewInit(): void {
    this.initLang();
  }
  initLang(): void {
    this.setCurrentLanguage(this.langService.getActiveLang());
  }

  /**
   * handles language dropdown changes
   */
  changeLanguage($event: Event, langId: string) {
    $event.preventDefault();
    // const prefix = `changeLanguage(${langId})`;
    const userLocale = langIdToLocale(langId);
    if (userLocale != '') {
      // console.debug(`${prefix} userLocale=${userLocale}`);
      this.langService.setActiveLang(langId);
      this.localeService.setLocale(userLocale);
      // } else {
      // console.debug(`${prefix} failed to find userLocale from langId`);
    }
  }

  /**
   * Update the current language in the language dropdown
   */
  setCurrentLanguage(langId: string) {
    const langDef = getLanguageDefinitions().find((i) => i.id == langId);
    if (langDef !== undefined) {
      this.currentLanguage = langDef.label;
    }
  }
}

export const footerLoader = scopeLoader((locale: string, root: string) => {
  const langPromises: Promise<Translation>[] = [];
  getLanguageFileNames(locale).forEach((filename) => {
    langPromises.push(
      import(`./assets/${root}/${filename}`).catch(
        // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
        () => {}
        // console.debug(`error importing language file (${filename}): ${err}`)
      )
    );
  });
  return combinePromises(langPromises);
});

@NgModule({
  imports: [CommonModule, DropdownModule, TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'footer',
        loader: footerLoader,
      },
    },
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterComponentModule {}
