import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgModule,
  ViewChild,
} from '@angular/core';
import { DialogRef } from '@mysas/portal/shared/data-access-dialog';
import { NotificationsFacade } from '@mysas/portal/shared/data-access-notifications';
import { INotification } from '@mysas/shared/data-access-notifications';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'mysas-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDialogComponent implements AfterViewInit {
  @ViewChild('dismissNotificationButton')
  firstFocus!: ElementRef;
  notifications$ = this.notificationsFacade.unreadNotifications$.pipe();
  constructor(
    private notificationsFacade: NotificationsFacade,
    private dialogRef: DialogRef
  ) {}

  ngAfterViewInit(): void {
    /* istanbul ignore next */
    this.firstFocus?.nativeElement.focus();
  }

  close() {
    this.dialogRef.close();
  }

  dismissNotification(notification: INotification) {
    this.notificationsFacade.dismissNotification(notification);
  }
}

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [NotificationDialogComponent],
  exports: [NotificationDialogComponent],
})
export class NotificationDialogComponentModule {}
