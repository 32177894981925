import { environmentDefaults } from './environment.defaults';
import { IEnvironment } from './environment.interface';
import { OKTA_CLIENT_ID_MAP, OKTA_ISSUER_URL_MAP } from './okta.constants';
// TODO - determine AppHostname for redirects for azr landing page etc
// BUG - azr landing page will not work until TODO is addressed

export const environment: IEnvironment = {
  ...environmentDefaults,
  production: true,
  // also hard-coded in the directive
  debugi18n: false,
  apiConnectorEndpoint: 'https://apigateway.sas.com/mysas-api/api/v1',
  chatConfig: {
    companyKey: 'sasinstitute',
    appId: '616ec3c2511cbb00e0531baf',
    widgetId: '4d7be2c6-f451-4b77-85aa-c97e85ea5667',
  },
  oidc: {
    clientId: OKTA_CLIENT_ID_MAP.prod,
    issuer: OKTA_ISSUER_URL_MAP.prod,
    redirectUri: 'https://my.sas.com/login/callback',
    postLogoutRedirectUri: `https://my.sas.com/signed-out`,
    scopes: ['email', 'profile', 'openid'],
    pkce: true,
    testing: {
      disableHttpsCheck: true,
    },
  },
  csmChat: {
    ...environmentDefaults.csmChat,
    scriptRef: 'https://service.sas.com/scripts/sn_csm_ec.js?v=5.3',
    globalModuleId: 'https://service.sas.com/#973624ac873fa1904f2543f5cebb35a5',
  },
  links: {
    ...environmentDefaults.links,
    csmMyCases: {
      url: 'https://service.sas.com/csm?id=csm_my_lists&table=sn_customerservice_case&view=csp&target_page_id=csm_ticket&filter=null&sel=all',
    },
    csmCreateCase: {
      url: 'https://service.sas.com/csm?id=sc_category&sys_id=1b8611d5c3921200b0449f2974d3ae12&catalog_id=-1&spa=1',
    },
  },
};
