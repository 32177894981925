import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { DialogService } from './dialog.service';

@NgModule({
  declarations: [],
  providers: [DialogService],
  imports: [OverlayModule, A11yModule],
  exports: [OverlayModule],
})
export class DialogModule {}
