import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { OrdersEffects } from './orders-state/orders.effects';
import { OrdersFacade } from './orders-state/orders.facade';
import * as fromOrders from './orders-state/orders.reducer';
import * as fromPreferences from './preferences-state/preferences.reducer';
import { PreferencesEffects } from './preferences-state/preferences.effects';
import { PreferencesFacade } from './preferences-state/preferences.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromOrders.ORDERS_FEATURE_KEY,
      fromOrders.ordersReducer
    ),
    EffectsModule.forFeature([OrdersEffects]),
    StoreModule.forFeature(
      fromPreferences.PREFERENCES_FEATURE_KEY,
      fromPreferences.preferencesReducer
    ),
    EffectsModule.forFeature([PreferencesEffects]),
  ],
  providers: [OrdersFacade, PreferencesFacade],
})
export class PortalSharedDataAccessOrdersModule {}
