import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  ignoreElements,
  map,
  switchMap,
  tap,
} from 'rxjs';

import { Action, Store } from '@ngrx/store';
import { NotificationsSelectors } from '../..';
import * as NotificationsActions from './notifications.actions';

export const LOCAL_STORAGE_NOTIFICATION_KEY = 'mysas-notifications';

@Injectable()
export class NotificationsEffects implements OnInitEffects {
  hydrate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.hydrateNotificationsFromStorage),
      map(() => {
        const storageValue = localStorage.getItem(
          LOCAL_STORAGE_NOTIFICATION_KEY
        );
        if (storageValue) {
          try {
            const notifications = JSON.parse(storageValue);
            return NotificationsActions.hydrateNotificationsSuccess({
              notifications,
            });
          } catch {
            localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY, '[]');
            return NotificationsActions.hydrateNotificationsFailure({
              error: `Unable to parse localStorage`,
            });
          }
        } else {
          localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY, '[]');
          return NotificationsActions.hydrateNotificationsSuccess({
            notifications: [],
          });
        }
      })
    );
  });

  /**
   * This effect listens for any changes to the state of the notifications, and automatically
   * serializes the list of entities to localStorage.
   */
  serialize$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          NotificationsActions.dismissNotification,
          NotificationsActions.addNotification,
          NotificationsActions.batchAddNotifications,
          NotificationsActions.deleteNotification,
          NotificationsActions.deleteMultipleNotifications,
          NotificationsActions.deleteAllNotifications
        ),
        switchMap(() =>
          this.store.select(NotificationsSelectors.getAllNotifications).pipe(
            map((result) => JSON.stringify(result)),
            catchError((err) => {
              this.handleSerializationError(err);
              return EMPTY;
            })
          )
        ),
        distinctUntilChanged(),
        tap((notifications) => {
          try {
            localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY, notifications);
          } catch (err) {
            this.handleSerializationError(err);
          }
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  clearNotificationsOnLogOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationsActions.deleteAllNotifications),
        tap(() => {
          try {
            localStorage.setItem(LOCAL_STORAGE_NOTIFICATION_KEY, `[]`);
          } catch (err) {
            this.handleSerializationError(err);
          }
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private store: Store) {}

  ngrxOnInitEffects(): Action {
    return NotificationsActions.hydrateNotificationsFromStorage();
  }

  private handleSerializationError(err: unknown) {
    console.error(
      `Unable to serialize notification state to localStorage`,
      err
    );
  }
}
