import { createAction, createActionGroup, props } from '@ngrx/store';
import { AuthState } from '@okta/okta-auth-js';

export const clearOktaUser = createAction('[User] Clear Okta User');

export const OktaActions = createActionGroup({
  source: 'Auth',
  events: {
    trigger: props<{ authState: AuthState }>(),
    store: props<{ authState: AuthState }>(),
  },
});
