import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { environment } from '@mysas/shared/util-environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  logger = inject(NGXLogger);
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.handleAccess(request, next);
  }

  private handleAccess(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Only add an access token to allowed origins. Rely on the Okta config first, fail back to environment if necessary
    /* istanbul ignore next */
    const a = new URL(this.oktaAuth.options.issuer ?? environment.oidc.issuer);
    const authUrl = a.protocol + '//' + a.host;
    const allowedOrigins = [
      environment.apiConnectorEndpoint,
      authUrl,
      'apigateway-dev.sas.com',
      'apigateway-stage.sas.com',
      'apigateway-test.sas.com',
      'apigateway.sas.com',
    ];
    if (allowedOrigins.some((url) => request.urlWithParams.includes(url))) {
      const accessToken = this.oktaAuth.getAccessToken();
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + accessToken,
        },
      });
      return next.handle(request);
    } else {
      this.logger.debug(
        `[AccessTokenInterceptor] Host ${request.url} is not in the allowedOrigins list, not attaching JWT`
      );
    }
    return next.handle(request);
  }
}
