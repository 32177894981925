import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from '@angular/core';

export type TokenStatus = 'default' | 'primary' | 'alert';

@Component({
  selector: 'token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenComponent {
  @Input() status: TokenStatus = 'default';
  @Input() dismissable = false;
}

@NgModule({
  imports: [CommonModule],
  declarations: [TokenComponent],
  exports: [TokenComponent],
})
export class TokenComponentModule {}
