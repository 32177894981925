import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  NgModule,
  OnInit,
} from '@angular/core';
import { DialogRef } from '@mysas/portal/shared/data-access-dialog';
import { PreferencesFacade } from '@mysas/portal/shared/data-access-orders';
import { TranslocoModule } from '@ngneat/transloco';
import { SpinnerComponentModule } from '../spinner/spinner.component';

@Component({
  selector: 'email-prefs-dialog',
  templateUrl: './email-prefs-dialog.component.html',
  styleUrls: ['./email-prefs-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailPrefsDialogComponent implements OnInit {
  prefsFacade = inject(PreferencesFacade);
  subscribedToVersionUpdates$ = this.prefsFacade.subscribedToVersionUpdates$;
  error$ = this.prefsFacade.error$;
  loaded$ = this.prefsFacade.loaded$;

  constructor(private dialogRef: DialogRef) {
    //
  }

  ngOnInit(): void {
    //
  }

  close() {
    this.dialogRef.close();
  }

  unsubscribe() {
    this.prefsFacade.unsubscribeFromVersionEmail();
  }

  subscribe() {
    this.prefsFacade.subscribeToVersionEmail();
  }
}

@NgModule({
  imports: [CommonModule, SpinnerComponentModule, TranslocoModule],
  declarations: [EmailPrefsDialogComponent],
  exports: [EmailPrefsDialogComponent],
})
export class EmailPrefsDialogComponentModule {}
