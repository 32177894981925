<div class="outage-alert outage-alert--{{ alert.level }}">
  <div class="outage-alert__icon">
    <svg
      *ngIf="alert.level === 'warn'"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.935 12.087L8.408 1C8.063 0.328 7.652 0 7 0C6.348 0 5.799 0.417 5.592 1L0.065 12.087C0.027 12.219 0 12.356 0 12.5C0 13.33 0.672 14 1.5 14H12.5C13.328 14 14 13.329 14 12.5C14 12.356 13.973 12.219 13.935 12.087ZM12.5 13H1.5C1.225 13 1 12.775 1 12.5C1 12.488 1.002 12.468 1.008 12.436L6.486 1.446C6.505 1.41 6.521 1.373 6.534 1.335C6.606 1.131 6.789 1 7 1C7.158 1 7.283 1 7.519 1.458L12.993 12.436C12.998 12.467 13 12.487 13 12.5C13 12.775 12.775 13 12.5 13ZM7 9C6.586 9 6.25 8.664 6.25 8.25V4.75C6.25 4.336 6.586 4 7 4C7.414 4 7.75 4.336 7.75 4.75V8.25C7.75 8.664 7.414 9 7 9ZM8 11C8 11.552 7.552 12 7 12C6.448 12 6 11.552 6 11C6 10.448 6.448 10 7 10C7.552 10 8 10.448 8 11Z"
        fill="#BC8A0B"
      />
    </svg>

    <svg
      *ngIf="alert.level === 'active'"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.854 9.146C10.049 9.341 10.049 9.658 9.854 9.853C9.756 9.951 9.628 10 9.5 10C9.372 10 9.244 9.951 9.146 9.854L7 7.707L4.854 9.854C4.756 9.951 4.628 10 4.5 10C4.372 10 4.244 9.951 4.146 9.854C3.951 9.659 3.951 9.342 4.146 9.147L6.293 7L4.146 4.854C3.951 4.659 3.951 4.342 4.146 4.147C4.341 3.952 4.658 3.952 4.853 4.147L7 6.293L9.146 4.147C9.341 3.952 9.658 3.952 9.853 4.147C10.048 4.342 10.048 4.659 9.853 4.854L7.707 7L9.854 9.146ZM14 7C14 10.866 10.866 14 7 14C3.134 14 0 10.866 0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7ZM13 7C13 3.691 10.309 1 7 1C3.691 1 1 3.691 1 7C1 10.309 3.691 13 7 13C10.309 13 13 10.309 13 7Z"
        fill="#B80000"
      />
    </svg>
  </div>

  <span class="outage-alert__text">{{ label }}</span>

  <button
    class="btn btn-sm btn-standard-no-bd-bg outage-alert__dismissable"
    *ngIf="alert.level === 'warn'"
    (click)="dismiss()"
  >
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.766 6.71299C8.058 7.00599 8.058 7.48199 7.765 7.77399C7.619 7.91999 7.427 7.99399 7.235 7.99399C7.043 7.99399 6.851 7.92099 6.704 7.77399L4.004 5.06999L1.282 7.78199C1.135 7.92699 0.943997 7.99999 0.751997 7.99999C0.559997 7.99999 0.366997 7.92699 0.220997 7.77899C-0.0710029 7.48599 -0.0710028 7.00999 0.222997 6.71799L2.944 4.00799L0.218997 1.27999C-0.0730027 0.986994 -0.0730028 0.511994 0.219997 0.219994C0.511997 -0.0730059 0.987997 -0.0730059 1.281 0.219994L4.007 2.94999L6.721 0.246994C7.015 -0.0450059 7.49 -0.0450058 7.782 0.248994C8.074 0.541994 8.074 1.01799 7.78 1.30999L5.067 4.00999L7.766 6.71299Z"
        fill="black"
      />
    </svg>
  </button>
</div>
