<div
  class="message-toast"
  [@fadeAnimation]="{
    value: animationState,
    params: {
      fadeIn: config.animation.fadeIn,
      fadeOut: config.animation.fadeOut
    }
  }"
  (@fadeAnimation.done)="onFadeFinished($event)"
>
  <p aria-live="polite" class="message-toast__text">
    {{ config.text }}
  </p>
</div>
