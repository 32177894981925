import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ordersAdapter,
  OrdersState,
  ORDERS_FEATURE_KEY,
} from './orders.reducer';

// Lookup the 'Orders' feature state managed by NgRx
export const getOrdersState =
  createFeatureSelector<OrdersState>(ORDERS_FEATURE_KEY);

const { selectAll, selectEntities } = ordersAdapter.getSelectors();

export const getOrdersLoaded = createSelector(
  getOrdersState,
  (state: OrdersState) => state.loaded
);

export const getOrdersError = createSelector(
  getOrdersState,
  (state: OrdersState) => state.error
);

export const getAllOrders = createSelector(
  getOrdersState,
  (state: OrdersState) => (state ? selectAll(state) : [])
);

export const getOrdersEntities = createSelector(
  getOrdersState,
  (state: OrdersState) => selectEntities(state)
);

export const getOrdersWithUpdatesAvailable = createSelector(
  getOrdersState,
  (state: OrdersState) =>
    state
      ? selectAll(state).filter((o) => o.updateStatus === 'updateAvailable')
      : []
);

export const getSelectedId = createSelector(
  getOrdersState,
  (state: OrdersState) => state.selectedId
);

export const getSelected = createSelector(
  getOrdersEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getOrdersSortBy = createSelector(
  getOrdersState,
  (state: OrdersState) => state.sortBy
);

export const getOrdersSortDirection = createSelector(
  getOrdersState,
  (state: OrdersState) => state.sortDirection
);

export const getOrdersFilter = createSelector(
  getOrdersState,
  (state: OrdersState) => state.filter ?? null
);

export const getAccessRequestState = createSelector(
  getOrdersState,
  ({ accessRequest }) => accessRequest
);
