import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  NgModule,
  ViewChild,
} from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import {
  DialogModule,
  DialogService,
} from '@mysas/portal/shared/data-access-dialog';

import { NotificationsFacade } from '@mysas/portal/shared/data-access-notifications';
import { UserFacade } from '@mysas/portal/shared/data-access-user';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { combineLatest, map } from 'rxjs';
import { HasBadgeDirectiveModule } from '../directives/has-badge.directive';
import { DropdownModule } from '../dropdown/dropdown-trigger-for.directive';
import {
  EmailPrefsDialogComponent,
  EmailPrefsDialogComponentModule,
} from '../email-prefs-dialog/email-prefs-dialog.component';
import {
  NotificationDialogComponent,
  NotificationDialogComponentModule,
} from '../notification-dialog/notification-dialog.component';
import { BannerService } from './banner.service';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent {
  @ViewChild('firstLinkUserMenu')
  userMenu!: ElementRef<HTMLElement>;

  currentUrl = '';
  skipLinkPath = 'main';

  bannerText$ = this.bannerService.bannerText$;

  notifications$ = this.notificationFacade.unreadNotifications$;

  user$ = combineLatest([
    this.userFacade.userName$,
    this.userFacade.userEmail$,
  ]).pipe(map(([name, email]) => ({ name, email })));

  constructor(
    private bannerService: BannerService,
    @Inject(OKTA_AUTH) private _authClient: OktaAuth,
    private notificationFacade: NotificationsFacade,
    private dialogService: DialogService,
    private userFacade: UserFacade,
    private router: Router,
    private translocoService: TranslocoService
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        if (!e.url.includes('#main')) {
          this.currentUrl = e.url;
        }
      }
    });
  }

  logout() {
    this._authClient.signOut({
      revokeAccessToken: true,
      revokeRefreshToken: true,
    });
    this.notificationFacade.deleteAllNotifications();
  }

  openNotificationDialog() {
    this.dialogService.open(NotificationDialogComponent, {
      data: this.notifications$,
    });
  }

  focusUserMenu() {
    this.userMenu.nativeElement.focus();
  }

  openPrefsDialog() {
    this.dialogService.open(EmailPrefsDialogComponent);
  }
}

@NgModule({
  imports: [
    CommonModule,
    HasBadgeDirectiveModule,
    DropdownModule,
    RouterModule,
    DialogModule,
    TranslocoModule,
    NotificationDialogComponentModule,
    EmailPrefsDialogComponentModule,
  ],
  providers: [BannerService],
  declarations: [BannerComponent],
  exports: [BannerComponent],
})
export class BannerComponentModule {}
