import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IAlert, IAlertOption } from '@mysas/shared/data-access-alerts';
import { IResponseEnvelope } from '@mysas/shared/data-access-common';
import { environment } from '@mysas/shared/util-environment';
import { OktaAuthStateService } from '@okta/okta-angular';
import { NGXLogger } from 'ngx-logger';

import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concat,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OutageAlertService {
  private loggedIn$ = inject(OktaAuthStateService).authState$;
  private http = inject(HttpClient);
  private readonly endpoint = `${environment.apiConnectorEndpoint}/alerts`;
  private readonly logger = inject(NGXLogger);

  private _alerts$$ = new BehaviorSubject<IAlert[]>([]);
  alerts$ = this._alerts$$.asObservable().pipe(shareReplay(1));

  private _options$$ = new BehaviorSubject<IAlertOption[]>([]);
  alertOptions$ = this._options$$.asObservable().pipe(shareReplay(1));

  private _error$$ = new BehaviorSubject<string | null>(null);
  error$ = this._error$$.asObservable();

  private _alertsLoading$$ = new BehaviorSubject<boolean>(false);
  private _optionsLoading$$ = new BehaviorSubject<boolean>(false);
  isLoading$ = combineLatest({
    alerts: this._alertsLoading$$.asObservable(),
    options: this._optionsLoading$$.asObservable(),
  }).pipe(map(({ alerts, options }) => alerts && options));

  /**
   * This is a convenience method meant to be used as part of the APP_INITIALIZER so as to keep
   * that block of code smaller. Using `concat` means the Observable emits each child's value in
   * order. This prevents the page from loading until both alerts and options have returned
   * data - and both methods will return an empty array in the case of an error.
   */
  initialize(): Observable<boolean> {
    this.logger.partialUpdateConfig({
      context: OutageAlertService.name,
      disableFileDetails: true,
    });
    this.loggedIn$
      .pipe(
        filter(({ isAuthenticated }) => isAuthenticated === true),
        switchMap(() => concat(this.loadOptions(), this.loadAlerts()))
      )
      .subscribe();
    return of(true);
  }

  loadAlerts(): Observable<IAlert[]> {
    this._alertsLoading$$.next(true);
    this._error$$.next(null);

    return this.http.get<IResponseEnvelope<IAlert[]>>(this.endpoint).pipe(
      map(({ data }) => data),
      tap((data) => {
        this._alerts$$.next(
          // sort so that active takes precedence over warn, then sort by date
          data.sort((a, b) => {
            if (a.level === 'active' && b.level === 'warn') {
              return -1;
            } else if (a.level === 'warn' && b.level === 'active') {
              return 1;
            } else if (a.level === b.level) {
              return Date.parse(a.startDate) - Date.parse(b.startDate);
            }
            return 0;
          })
        );
        this.logger.debug(`Sorted alerts:`, data);
        this._alertsLoading$$.next(false);
      }),

      catchError((err: Error) => {
        console.error(err);
        this._error$$.next(err.message);
        this._alertsLoading$$.next(false);
        return of([]);
      }),
      tap((alerts) => {
        this.logger.debug(`Finished retrieving alerts!`, alerts);
      })
    );
  }

  loadOptions(): Observable<IAlertOption[]> {
    this._optionsLoading$$.next(true);
    this._error$$.next(null);

    return this.http
      .get<IResponseEnvelope<IAlertOption[]>>(`${this.endpoint}/options`)
      .pipe(
        map(({ data }) => data),
        tap((options) => {
          this._options$$.next(options);
          this._optionsLoading$$.next(false);
        }),
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            this._error$$.next(err.message);
          } else {
            this._error$$.next(
              `Unknown error occurred while loading alert options`
            );
            console.error(err);
          }
          this._optionsLoading$$.next(false);
          return of([]);
        }),
        tap((options) => {
          this.logger.debug(`Finished loading alert options`, options);
        })
      );
  }
}
