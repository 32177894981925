import { NgxLoggerLevel } from 'ngx-logger';

export const PortalLogLevel: Record<string, NgxLoggerLevel> = {
  local: NgxLoggerLevel.DEBUG,
  development: NgxLoggerLevel.DEBUG,
  'k8s-dev': NgxLoggerLevel.DEBUG,
  'k8s-test': NgxLoggerLevel.DEBUG,
  'k8s-staging': NgxLoggerLevel.DEBUG,
  production: NgxLoggerLevel.ERROR,
};
