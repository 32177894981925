import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey
);

export const selectUserName = createSelector(selectUserState, (s) => s.name);

export const selectUserEmail = createSelector(selectUserState, (s) => s.email);

export const selectUserPreferredUsername = createSelector(
  selectUserState,
  (s) => s.preferred_username
);

export const selectVspTLA = createSelector(
  selectUserState,
  ({ vspTLA }) => vspTLA
);
