/* eslint-disable no-restricted-syntax */
import { NgModule } from '@angular/core';
import { environment } from '@mysas/shared/util-environment';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_FALLBACK_STRATEGY,
  TRANSLOCO_LOADER,
  TranslocoModule,
  translocoConfig
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import {
  TRANSLOCO_PERSIST_LANG_STORAGE,
  TranslocoPersistLangModule,
} from '@ngneat/transloco-persist-lang';
import { CustomFallbackStrategy } from './custom-fallback-strategy';
import { i18nConfig, l10nConfig } from './i18n.config';
import {
  getLangFn
} from './scoped-translations';
import { TranslocoHttpPropertyLoader } from './transloco-http-property-loader';

export const customFallbackStrategy = {
  provide: TRANSLOCO_FALLBACK_STRATEGY,
  useClass: CustomFallbackStrategy,
};

@NgModule({
  exports: [TranslocoModule, TranslocoLocaleModule],
  imports: [
    TranslocoLocaleModule.forRoot(l10nConfig),
    TranslocoPersistLangModule.forRoot({
      getLangFn,
      storage: {
        provide: TRANSLOCO_PERSIST_LANG_STORAGE,
        useValue: localStorage,
      },
    }),
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        ...i18nConfig,
        prodMode: environment.production,
      }),
    },
    customFallbackStrategy,
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpPropertyLoader },
  ],
})
export class TranslocoRootModule {}
