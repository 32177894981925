import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';

export type ToastType = 'warning' | 'info' | 'success';

export interface IToastConfig {
  text: string;
  type: ToastType;
  position?: {
    bottom: number;
    right: number;
  };
  animation: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const defaultToastConfig: IToastConfig = {
  position: {
    bottom: 32,
    right: 32,
  },
  animation: {
    fadeIn: 300,
    fadeOut: 2500,
  },
  type: 'info',
  text: 'Message Toast!',
};

export const TOAST_CONFIG = new InjectionToken('TOAST_CONFIG');

export const TOAST_COMPONENT = new InjectionToken<ComponentType<unknown>>(
  'TOAST_COMPONENT'
);
