import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsFacade } from '@mysas/portal/shared/data-access-notifications';
import { UserFacade } from '@mysas/portal/shared/data-access-user';
import { INotification } from '@mysas/shared/data-access-notifications';
import { TranslocoService } from '@ngneat/transloco';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects,
} from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
// import { selectQueryParamNgrx } from 'libs/portal/shared/util-state/src/lib/router-selectors';
import { routerSelectors } from '@mysas/portal/shared/util-state';
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  firstValueFrom,
  ignoreElements,
  map,
  of,
  skipUntil,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { OrdersService } from '../services/orders.service';
import * as OrdersSelectors from './orders.selectors';

import {
  DialogRef,
  DialogService,
} from '@mysas/portal/shared/data-access-dialog';
import { ToasterService } from '@mysas/portal/shared/data-access-toaster';
import * as OrdersActions from './orders.actions';

@Injectable()
export class OrdersEffects implements OnInitEffects {
  private dialogRef: DialogRef | undefined;
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrdersActions.initOrders),
      skipUntil(
        this.userFacade.loggedIn$.pipe(filter((loggedIn) => loggedIn === true))
      ),
      switchMap(() =>
        this.ordersService.getOrders().pipe(
          map((orders) => OrdersActions.loadOrdersSuccess({ orders })),
          catchError((err) => {
            // TODO possible type issue? err will likely have type 'Error', but loadOrdersFailure.error expects 'string'
            return of(OrdersActions.loadOrdersFailure({ error: err }));
          })
        )
      )
    );
  });

  updateNotifications$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrdersActions.loadOrdersSuccess),
        map(({ orders }) => {
          const ordersWithUpdates = orders.filter(
            (o) => o.updateStatus === 'updateAvailable'
          );
          if (ordersWithUpdates.length) {
            const notifications: INotification[] = ordersWithUpdates.reduce(
              (acc: INotification[], order) => {
                acc.push({
                  title: this.transloco.translate(
                    'notificationUpdatesAvailable.label'
                  ),
                  text: this.transloco.translate(
                    'notificationUpdatesAvailable.txt',
                    {
                      siteNum: order.siteNum,
                      siteName: order.siteName,
                    }
                  ),
                  slug: `updates-available-${order.number}`,
                  dismissed: false,
                });
                return acc;
              },
              []
            );
            this.notificationFacade.batchAddNotifications(notifications);
          }
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  /**
   * Effect used for updating the notifications language whenever it changes.
   *
   * The reason `selectTranslate` is used with a number of Promises is due to timing - sometimes
   * this effect would run before the language was loaded, resulting in notifications not translated
   * until the page was reloaded.
   */
  watchForLanguageChange$ = createEffect(
    () => {
      return this.transloco.events$.pipe(
        filter((event) => event.type === 'langChanged'),
        withLatestFrom(
          this.store.select(OrdersSelectors.getOrdersWithUpdatesAvailable)
        ),
        map(async ([event, orders]) => {
          await Promise.all(
            orders.map(async (o) => ({
              title: await firstValueFrom(
                this.transloco.selectTranslate(
                  'notificationUpdatesAvailable.label',
                  {},
                  event.payload.langName
                )
              ),
              text: await firstValueFrom(
                this.transloco.selectTranslate(
                  'notificationUpdatesAvailable.txt',
                  { siteNum: o.siteNum, siteName: o.siteName },
                  event.payload.langName
                )
              ),
              slug: `updates-available-${o.number}`,
              dismissed: false,
            }))
          ).then((notifications: INotification[]) => {
            // console.log(
            //   `watchForLanguageChange$ :: new notification titles:\n${notifications
            //     .map((n) => n.title)
            //     .join('\n')}`
            // );
            this.notificationFacade.batchAddNotifications(notifications);
          });
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  dialogOpened$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrdersActions.openAccessRequestDialog),
        tap(({ component }) => {
          if (this.dialogRef) {
            this.dialogRef = undefined;
          }
          this.dialogRef = this.dialogService.open(component);
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  submitAccessRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrdersActions.submitAccessRequest),
      switchMap(({ payload }) =>
        this.ordersService
          .requestAccess(payload.orderNumber, payload.siteNumber)
          .pipe(
            map((resp) => OrdersActions.submitAccessRequestSuccess({ resp })),
            catchError((err: Error) =>
              of(
                OrdersActions.submitAccessRequestFailure({
                  error: err.message,
                })
              )
            )
          )
      )
    );
  });

  accessRequestSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrdersActions.submitAccessRequestSuccess),
      map(() =>
        OrdersActions.closeAccessRequestDialog({
          toastType: 'success',
          text: this.transloco.translate(
            'accessReqSubmittedSuccessfully.label'
          ),
        })
      )
    );
  });

  accessRequestDialogClosed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrdersActions.closeAccessRequestDialog),
        tap(({ toastType, text }) => {
          this.dialogRef?.close();
          this.toastService.open({
            type: toastType,
            text,
            position: { bottom: 128, right: 32 },
          });
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  /**
   * This effect updates the URL whenever the filter for the list of orders has changed. It
   * uses the `distinctUntilKeyChanged` operator to prevent an endless cycle of updates where
   * `updateFilterFromUrl$` sees the URL change and acts accordingly.
   */
  updateUrlSiteFilter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OrdersActions.changeFilter),
        distinctUntilKeyChanged('filter'),
        map(({ filter }) => {
          const params = filter ? { filter } : null;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
          });
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  /**
   * This effect watches URL changes for a route that starts with '/orders', and then attempts
   * to extract a value for the query param 'siteNumber'. If this param doesn't exist or it's value is null,
   * then the filter is set to null as well.
   */
  updateFilterFromUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => payload.routerState.url.startsWith('/orders')),
      concatLatestFrom(() =>
        this.store.select(routerSelectors.selectQueryParamNgrx('filter'))
      ),
      map(([, filter]) => {
        return OrdersActions.changeFilter({ filter: filter ?? null });
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private ordersService: OrdersService,
    private notificationFacade: NotificationsFacade,
    private userFacade: UserFacade,
    private dialogService: DialogService,
    private toastService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private transloco: TranslocoService
  ) {}

  ngrxOnInitEffects(): Action {
    return OrdersActions.initOrders();
  }
}
