<ng-container *transloco="let t">
  <div
    class="dialog"
    *ngIf="notifications$ | async as notifications"
    role="dialog"
    [attr.aria-label]="t('notDialogNotifications.title')"
  >
    <div class="dialog__content">
      <div class="row">
        <div class="col">
          <h2 class="dialog__title">
            {{ t('notDialogNotifications.title') }} ({{ notifications.length }})
          </h2>
        </div>
      </div>

      <div class="row" style="margin-block-start: var(--space-large)">
        <div class="col">
          <ul class="notification-list">
            <li
              *ngFor="let n of notifications; let i = index"
              class="notification-list__item"
            >
              <div class="notification-text">
                <span class="title">{{ n.title }}</span>
                <span class="details">{{ n.text }}</span>
              </div>

              <div class="notification-actions">
                <button
                  tabindex="0"
                  class="btn btn-standard-no-bd-bg"
                  (click)="dismissNotification(n)"
                  id="{{ 'dismiss-button-' + i }}"
                  attr.aria-label="{{ t('notDialogDismiss.label') }}"
                >
                  <svg
                    localeOverrides="{}"
                    xmlns="http://www.w3.org/2000/svg"
                    class="Delete"
                    width="14"
                    height="14"
                    version="1.1"
                    viewBox="0 0 14 14"
                    xml:space="preserve"
                    attr.aria-label="{{ t('notDialogDismiss.aria') }}"
                  >
                    <path
                      class="svgToolTipPane"
                      fill="none"
                      d="M0 0H14V14H0z"
                    ></path>
                    <path
                      class="iconColor"
                      d="M12.5,2H10V0H4v2H1.5C1.225,2,1,2.225,1,2.5C1,2.775,1.225,3,1.5,3h11C12.775,3,13,2.775,13,2.5 C13,2.225,12.775,2,12.5,2z M5,1h4v1H5V1z M2,4v9c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1V4H2z M11,13H3V5h8V13z M9,11.5v-5 C9,6.225,8.775,6,8.5,6h0C8.225,6,8,6.225,8,6.5v5C8,11.775,8.225,12,8.5,12h0C8.775,12,9,11.775,9,11.5z M6,11.5v-5 C6,6.225,5.775,6,5.5,6h0C5.225,6,5,6.225,5,6.5v5C5,11.775,5.225,12,5.5,12h0C5.775,12,6,11.775,6,11.5z"
                    ></path>
                  </svg>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="dialog__footer">
      <div class="row">
        <div class="col d-flex justify-content-end">
          <button
            #dismissNotificationButton
            id="dismiss-notification-button"
            class="btn btn-primary"
            (click)="close()"
            attr.aria-label="{{ t('notDialogDone.label') }}"
          >
            {{ t('notDialogDone.label') }}
          </button>
        </div>
      </div>
    </div>

    <div class="dialog__dismiss">
      <button
        class="btn btn-standard-no-bd-bg"
        (click)="close()"
        attr.aria-label="{{ t('notDialogClose.aria') }}"
      ></button>
    </div>
  </div>
</ng-container>
