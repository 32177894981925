<ng-container *transloco="let t; read: 'footer'">
  <div class="row footer-row no-gutters">
    <div class="col footer-col">
      <a
        href="https://www.sas.com/en_us/legal/copyright.geo.html"
        target="_blank"
        class="footer-link"
        >{{ t('footerTermsLegoInfo.label') }}</a
      >
    </div>

    <div class="col footer-col">
      <a
        href="https://www.sas.com/en_us/legal/privacy.geo.html"
        target="_blank"
        class="footer-link"
        id="privacy-anchor"
        >{{ t('footerPrivacy.label') }}</a
      >
    </div>

    <div class="col footer-col">
      <span id="span-test" class="footer-link">{{
        t('footerCopyright.label')
      }}</span>
    </div>

    <div class="col footer-col locale-col">
      <button
        class="btn btn-standard-no-bd btn-dropdown"
        id="local-dropdown-btn"
        attr.aria-label="{{
          t('footerChangeLanguage.aria', { language: currentLanguage })
        }}"
        [dropdownTriggerFor]="localDropdown"
        (click)="onClickLanguageDropdownButton()"
        aria-haspopup="menu"
      >
        {{ currentLanguage }}
      </button>
    </div>
  </div>

  <dropdown #localDropdown>
    <ul class="menu" #languageDropdown>
      <li
        class="menu-item"
        lang="{{ lang.id }}"
        *ngFor="let lang of availableLanguages"
      >
        <!-- links are used here for a11y purposes -->
        <a
          href="#"
          id="menu-item-link-{{ lang.id }}"
          (click)="changeLanguage($event, lang.id)"
          style="color: var(--color-text-default); display: block"
          attr.aria-label="{{
            t('footerChangeLanguage.aria', { language: lang.label })
          }}"
        >
          {{ lang.label }}
        </a>
      </li>
    </ul>
  </dropdown>
</ng-container>
