import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject, takeUntil } from 'rxjs';

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class DialogRef<C = unknown> {
  readonly componentInstance: C | null = null;
  private afterClosedSubject = new Subject<unknown>();

  constructor(private overlayRef: OverlayRef) {
    overlayRef
      .backdropClick()
      .pipe(takeUntil(this.afterClosedSubject))
      .subscribe(() => {
        this.close();
      });
  }

  /**
   * Closes the overlay. You can optionally provide a result.
   */
  public close(result?: unknown) {
    this.overlayRef.dispose();
    this.afterClosedSubject.next(result);
    this.afterClosedSubject.complete();
    (this as { componentInstance: C | null }).componentInstance = null;
  }

  /**
   * An Observable that notifies when the overlay has closed
   */
  public afterClosed(): Observable<unknown> {
    return this.afterClosedSubject.asObservable();
  }
}
