<ng-container *transloco="let t">
  <div class="error-container">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="error-icon"
    >
      <path
        d="M16.8926 15.6789C17.2269 16.0131 17.2269 16.5566 16.8926 16.8909C16.7246 17.0589 16.5051 17.1429 16.2857 17.1429C16.0663 17.1429 15.8469 17.0589 15.6789 16.8926L12 13.212L8.32114 16.8926C8.15314 17.0589 7.93371 17.1429 7.71429 17.1429C7.49486 17.1429 7.27543 17.0589 7.10743 16.8926C6.77314 16.5583 6.77314 16.0149 7.10743 15.6806L10.788 12L7.10743 8.32114C6.77314 7.98686 6.77314 7.44343 7.10743 7.10914C7.44171 6.77486 7.98514 6.77486 8.31943 7.10914L12 10.788L15.6789 7.10914C16.0131 6.77486 16.5566 6.77486 16.8909 7.10914C17.2251 7.44343 17.2251 7.98686 16.8909 8.32114L13.212 12L16.8926 15.6789ZM24 12C24 18.6274 18.6274 24 12 24C5.37257 24 0 18.6274 0 12C0 5.37257 5.37257 0 12 0C18.6274 0 24 5.37257 24 12ZM22.2857 12C22.2857 6.32743 17.6726 1.71429 12 1.71429C6.32743 1.71429 1.71429 6.32743 1.71429 12C1.71429 17.6726 6.32743 22.2857 12 22.2857C17.6726 22.2857 22.2857 17.6726 22.2857 12Z"
        fill="var(--color-status-alert)"
      />
    </svg>

    <h3 class="error-header">404</h3>

    <span class="error-text">{{ t('fallbackNotFound.txt') }}</span>
  </div>
</ng-container>
