import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingErrorComponent } from '@mysas/portal/orders/ui';
import {
  AlertComponentModule,
  DropdownModule,
  SpinnerComponentModule,
  TabsModule,
  TokenComponentModule,
} from '@mysas/portal/shared/ui-layout';
import {
  DevIdentifyI18nDirectiveModule,
  combinePromises,
  getLanguageFileNames,
  scopeLoader,
} from '@mysas/shared/util-i18n';
import {
  TRANSLOCO_SCOPE,
  Translation,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { AssetPanelComponent } from './asset-panel/asset-panel.component';
import { PanelService } from './asset-panel/panel.service';
import { DownloadsToolbarComponent } from './downloads-toolbar/downloads-toolbar.component';
import { ManageAccessComponent } from './manage-access/manage-access.component';
import { OrderBasicInfoComponent } from './order-basic-info/order-basic-info.component';
import { AssetTypePipeModule } from './pipes';
import { UnableToDownloadComponent } from './unable-to-download/unable-to-download.component';
import { VersionAssetTableComponent } from './version-asset-table/version-asset-table.component';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    TranslocoModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    DevIdentifyI18nDirectiveModule,
    TokenComponentModule,
    DropdownModule,
    AssetTypePipeModule,
    SpinnerComponentModule,
    AlertComponentModule,
    LoadingErrorComponent,
    TranslocoLocaleModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'orderOverview',
        loader: scopeLoader((locale: string, root: string) => {
          const langPromises: Promise<Translation>[] = [];
          getLanguageFileNames(locale).forEach((filename) => {
            langPromises.push(
              import(`./assets/${root}/${filename}`).catch((err) =>
                console.error(
                  `error importing language file (${filename}): ${err}`
                )
              )
            );
          });

          return combinePromises(langPromises);
        }),
      },
    },
    PanelService,
  ],
  declarations: [
    AssetPanelComponent,
    VersionAssetTableComponent,
    DownloadsToolbarComponent,
    OrderBasicInfoComponent,
    ManageAccessComponent,
    UnableToDownloadComponent,
  ],
  exports: [
    AssetPanelComponent,
    VersionAssetTableComponent,
    DownloadsToolbarComponent,
    OrderBasicInfoComponent,
    ManageAccessComponent,
    UnableToDownloadComponent,
  ],
})
export class UiOrderOverviewModule {}
