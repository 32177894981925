/* eslint-disable @typescript-eslint/no-explicit-any */
import { ILoadingState } from '@mysas/portal/shared/util-state';
import {
  IAsset,
  IOrderAvailableCadence,
  IOrderDetail,
  IOrderUser,
} from '@mysas/shared/data-access-orders';
import {
  Action,
  ActionCreator,
  createReducer,
  Creator,
  on,
  ReducerTypes,
} from '@ngrx/store';

import * as OrderOverviewActions from './order-overview.actions';

export const ORDER_OVERVIEW_FEATURE_KEY = 'orderOverview';

export interface OrderOverviewState extends Partial<IOrderDetail> {
  loading: {
    details: ILoadingState;
    users: ILoadingState;
    cadences: ILoadingState;
    history: ILoadingState;
  };
  loaded: boolean; // has the OrderOverview been loaded
  error?: string | null; // last known error (if any)
  users: IOrderUser[];
  onHold: boolean;
  addUserRequest: {
    processing: boolean;
    error: string | undefined;
  };
  availableCadences: IOrderAvailableCadence[];
  downloadHistory: IAsset[];
  assetPanel: {
    loaded: boolean;
    error?: string;
  };
  assetDownloadInProgress: {
    uriName: string;
    uriVersion: string;
  } | null;
  filters: {
    filter: string | null;
    selectedCadence: string | null;
    selectedVersion: string | null;
    selectedAssetType: string | null;
  };
  updateUserPermissionRequest: {
    processing: boolean;
  };
}

export interface OrderOverviewPartialState {
  readonly [ORDER_OVERVIEW_FEATURE_KEY]: OrderOverviewState;
}

export const initialOrderOverviewState: OrderOverviewState = {
  loading: {
    details: {
      status: 'NOT_LOADED',
      success: false,
    },
    users: {
      status: 'NOT_LOADED',
      success: false,
    },
    cadences: {
      status: 'NOT_LOADED',
      success: false,
    },
    history: {
      status: 'NOT_LOADED',
      success: false,
    },
  },
  loaded: false,
  onHold: false,
  users: [],
  addUserRequest: {
    processing: false,
    error: undefined,
  },
  availableCadences: [],
  downloadHistory: [],
  assetPanel: {
    loaded: false,
    error: undefined,
  },
  assetDownloadInProgress: null,
  filters: {
    filter: null,
    selectedCadence: null,
    selectedVersion: null,
    selectedAssetType: null,
  },
  updateUserPermissionRequest: {
    processing: false,
  },
};

const orderCadenceOns: ReducerTypes<
  OrderOverviewState,
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    OrderOverviewActions.LoadOrderCadences.load,
    (state): OrderOverviewState => ({
      ...state,
      availableCadences: [],
      loading: {
        ...state.loading,
        cadences: {
          status: 'LOADING',
          success: false,
        },
      },
    })
  ),
  on(
    OrderOverviewActions.LoadOrderCadences.success,
    (state, { availableCadences }): OrderOverviewState => ({
      ...state,
      availableCadences,
      loading: {
        ...state.loading,
        cadences: {
          status: 'LOADED',
          success: true,
        },
      },
    })
  ),
  on(OrderOverviewActions.LoadOrderCadences.failure, (state, { error }) => ({
    ...state,
    loading: {
      ...state.loading,
      cadences: {
        status: 'LOADED',
        success: false,
        error,
      },
    },
  })),
];

const orderDetailOns: ReducerTypes<
  OrderOverviewState,
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    OrderOverviewActions.LoadOrderDetail.load,
    (state): OrderOverviewState => ({
      ...state,
      availableCadences: [],
      loading: {
        ...state.loading,
        details: {
          status: 'LOADING',
          success: false,
        },
      },
    })
  ),
  on(
    OrderOverviewActions.LoadOrderDetail.success,
    (state, { detail }): OrderOverviewState => ({
      ...state,
      ...detail,
      onHold: detail.downloadStatus === 'hold',
      loading: {
        ...state.loading,
        details: {
          status: 'LOADED',
          success: true,
        },
      },
    })
  ),
  on(OrderOverviewActions.LoadOrderDetail.failure, (state, { error }) => ({
    ...state,
    loading: {
      ...state.loading,
      details: {
        status: 'LOADED',
        success: false,
        error,
      },
    },
  })),
];

const orderUserOns: ReducerTypes<
  OrderOverviewState,
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    OrderOverviewActions.LoadOrderUsers.load,
    (state): OrderOverviewState => ({
      ...state,
      availableCadences: [],
      loading: {
        ...state.loading,
        users: {
          status: 'LOADING',
          success: false,
        },
      },
    })
  ),
  on(
    OrderOverviewActions.LoadOrderUsers.success,
    (state, { users }): OrderOverviewState => ({
      ...state,
      users,
      loading: {
        ...state.loading,
        users: {
          status: 'LOADED',
          success: true,
        },
      },
    })
  ),
  on(OrderOverviewActions.LoadOrderUsers.failure, (state, { error }) => ({
    ...state,
    loading: {
      ...state.loading,
      users: {
        status: 'LOADED',
        success: false,
        error,
      },
    },
  })),
];

const orderHistoryOns: ReducerTypes<
  OrderOverviewState,
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    OrderOverviewActions.LoadOrderDownloadHistory.load,
    (state): OrderOverviewState => ({
      ...state,
      availableCadences: [],
      loading: {
        ...state.loading,
        history: {
          status: 'LOADING',
          success: false,
        },
      },
    })
  ),
  on(
    OrderOverviewActions.LoadOrderDownloadHistory.success,
    (state, { downloadHistory }): OrderOverviewState => ({
      ...state,
      downloadHistory,
      loading: {
        ...state.loading,
        history: {
          status: 'LOADED',
          success: true,
        },
      },
    })
  ),
  on(
    OrderOverviewActions.LoadOrderDownloadHistory.failure,
    (state, { error }) => ({
      ...state,
      loading: {
        ...state.loading,
        history: {
          status: 'LOADED',
          success: false,
          error,
        },
      },
    })
  ),
];

const assetDownloadOns: ReducerTypes<
  OrderOverviewState,
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    OrderOverviewActions.DownloadAssetsActions.downloadRequested,
    (state, { uriName, uriVersion }): OrderOverviewState => ({
      ...state,
      assetDownloadInProgress: { uriName, uriVersion },
    })
  ),
  on(
    OrderOverviewActions.DownloadAssetsActions.downloadFailure,
    (state, { error }): OrderOverviewState => ({
      ...state,
      error,
    })
  ),
  on(
    OrderOverviewActions.DownloadAssetsActions.downloadFailure,
    OrderOverviewActions.DownloadAssetsActions.downloadSuccessful,
    (state): OrderOverviewState => ({
      ...state,
      assetDownloadInProgress: null,
    })
  ),
];

const filterActionOns: ReducerTypes<
  OrderOverviewState,
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    OrderOverviewActions.ChangeFilter.selectCadence,
    (state, { selectedCadence }): OrderOverviewState => ({
      ...state,
      filters: {
        ...state.filters,
        selectedCadence,
        selectedVersion: null,
        selectedAssetType: null,
      },
    })
  ),
  on(
    OrderOverviewActions.ChangeFilter.selectVersion,
    (state, { selectedVersion }): OrderOverviewState => ({
      ...state,
      filters: {
        ...state.filters,
        selectedVersion,
      },
    })
  ),
  on(
    OrderOverviewActions.ChangeFilter.selectAssetType,
    (state, { selectedAssetType }): OrderOverviewState => ({
      ...state,
      filters: {
        ...state.filters,
        selectedAssetType,
      },
    })
  ),
  on(OrderOverviewActions.ChangeFilter.updateFilter, (state, { filter }) => ({
    ...state,
    filters: {
      ...state.filters,
      filter,
    },
  })),
  on(OrderOverviewActions.ChangeFilter.clearFilters, (state) => ({
    ...state,
    filters: {
      ...initialOrderOverviewState.filters,
    },
  })),
];

const reducer = createReducer(
  initialOrderOverviewState,
  ...orderCadenceOns,
  ...orderDetailOns,
  ...orderUserOns,
  ...orderHistoryOns,
  ...assetDownloadOns,
  ...filterActionOns,
  on(
    OrderOverviewActions.initOrderOverview,
    (): OrderOverviewState => ({
      ...initialOrderOverviewState,
      loaded: false,
      error: null,
    })
  ),
  // TODO remove?
  // on(
  //   OrderOverviewActions.loadOrderDetailFailure,
  //   OrderOverviewActions.loadOrderUsersFailure,
  //   // OrderOverviewActions.LoadOrderCadenceActions.loadFailure,
  //   (state, { error }): OrderOverviewState => ({
  //     ...state,
  //     error,
  //   })
  // ),
  // on(
  //   OrderOverviewActions.loadOrderDetailSuccess,
  //   (state, { detail }): OrderOverviewState => ({
  //     ...state,
  //     ...detail,
  //     loaded: !!state.users,
  //     onHold: detail.downloadStatus === 'hold',
  //   })
  // ),
  // on(
  //   OrderOverviewActions.loadOrderUsersSuccess,
  //   (state, { users }): OrderOverviewState => ({
  //     ...state,
  //     users,
  //     loaded: !!state.siteNum,
  //   })
  // ),
  ///////// add user actions
  on(
    OrderOverviewActions.addUser,
    (state): OrderOverviewState => ({
      ...state,
      addUserRequest: {
        processing: true,
        error: undefined,
      },
    })
  ),
  /**
   * This reducer manually adds the 'type' property since `type` already exists as a
   * property on NgRx actions and we don't want to override that. When a user is adding
   * another to an order, the invitee is inherintly of type 'user' so it's safe to assign
   * that value here
   */
  on(
    OrderOverviewActions.addUserSuccess,
    (state, { user }): OrderOverviewState => ({
      ...state,
      users: state.users?.length
        ? [...state.users, { ...user, type: 'user' }]
        : [{ ...user, type: 'user' }],
      addUserRequest: {
        processing: false,
        error: undefined,
      },
    })
  ),
  on(
    OrderOverviewActions.addUserFailure,
    (state, { error }): OrderOverviewState => ({
      ...state,
      addUserRequest: {
        processing: false,
        error,
      },
    })
  ),
  on(
    OrderOverviewActions.closeAddUserDialog,
    OrderOverviewActions.addUserReset,
    (state): OrderOverviewState => ({
      ...state,
      addUserRequest: {
        ...initialOrderOverviewState.addUserRequest,
      },
    })
  ),
  // on successful removal of a user, just update the list of users in memory
  on(
    OrderOverviewActions.RemoveUserActions.removeSuccess,
    (state, { email }): OrderOverviewState => ({
      ...state,
      users: [...state.users.filter((u) => u.email !== email)],
    })
  ),
  // update user permissions
  on(
    OrderOverviewActions.ChangeUserPermissionActions.changePermission,
    (state): OrderOverviewState => ({
      ...state,
      updateUserPermissionRequest: {
        processing: true,
      },
    })
  ),
  on(
    OrderOverviewActions.ChangeUserPermissionActions.changeSuccess,
    (state, { email, role }): OrderOverviewState => ({
      ...state,
      updateUserPermissionRequest: {
        processing: false,
      },
      users: [...state.users].map((u) =>
        u.email === email ? { ...u, role } : u
      ),
    })
  ),
  on(
    OrderOverviewActions.ChangeUserPermissionActions.changeFailure,
    OrderOverviewActions.ChangeUserPermissionActions.changeSuccess,
    (state): OrderOverviewState => ({
      ...state,
      updateUserPermissionRequest: {
        processing: false,
      },
    })
  )
);

export function orderOverviewReducer(
  state: OrderOverviewState | undefined,
  action: Action
) {
  return reducer(state, action);
}
