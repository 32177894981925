import { Component, HostListener, inject, Inject, OnInit } from '@angular/core';
import { NotificationsFacade } from '@mysas/portal/shared/data-access-notifications';
import {
  UserLoggedInProvider,
  USER_LOGGED_IN,
} from '@mysas/shared/ui-data-access-auth';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';

import { OutageAlertService } from '@mysas/portal/shared/data-access-outages';
import { loadVersion } from '@mysas/shared/util-environment';
import { environment } from '../environments/environment';

@Component({
  selector: 'mysas-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  notificationsFacade = inject(NotificationsFacade);
  langService = inject(TranslocoService);
  localeService = inject(TranslocoLocaleService);
  private outageService = inject(OutageAlertService);

  outageAlerts$ = this.outageService.alerts$;
  outageAlertOptions$ = this.outageService.alertOptions$;

  @HostListener('document:keydown.tab', ['$event']) handleKeyboardEvent(
    event: KeyboardEvent
  ) {
    const a = document.querySelector(
      'a#link-skip-to-main-content'
    ) as HTMLElement;
    if (a) {
      a.style.display = 'inline';
    }
  }

  constructor(
    @Inject(USER_LOGGED_IN) public isLoggedIn$: UserLoggedInProvider
  ) {}

  ngOnInit() {
    if (!environment.production) {
      const v = loadVersion();
      console.log(`Version:SHA:       ${v.SHA}`);
      console.log(`Version:Short SHA: ${v.shortSHA}`);
      console.log(`Version:Tag:       ${v.tag}`);
      // this line is part of the unit tests, so if it changes, please change the tests
      console.log(`Version:BuildTime: ${v.buildTime}`);
    }
    // this.outageService.loadAlerts();
  }
}
