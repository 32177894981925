import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OrderOverviewFacade } from '@mysas/portal/orders/data-access-orders';
import { AssetType } from '@mysas/shared/data-access-orders';
import { debounceTime, Observable, of, takeUntil } from 'rxjs';

type DownloadsToolbarFormType = {
  filter: FormControl<string | null>;
  selectedCadence: FormControl<string | null>;
  selectedVersion: FormControl<string | null>;
  selectedAssetType: FormControl<string | null>;
};

@Component({
  selector: 'mysas-downloads-toolbar',
  templateUrl: './downloads-toolbar.component.html',
  styleUrls: ['./downloads-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadsToolbarComponent implements OnInit, OnDestroy {
  private overviewFacade = inject(OrderOverviewFacade);

  filterForm = new FormGroup<DownloadsToolbarFormType>({
    filter: new FormControl<string | null>(null),
    selectedCadence: new FormControl<string | null>(null),
    selectedVersion: new FormControl<string | null>(null),
    selectedAssetType: new FormControl<string | null>(null),
  });

  cadenceDropdownOptions$ = this.overviewFacade.cadenceDropdownOptions$;
  versionDropdownOptions$ = this.overviewFacade.versionDropdownOptions$;
  assetTypeDropdownOptions$: Observable<AssetType[]> = of([
    'certificates',
    'deploymentAssets',
    'license',
  ]);

  filterState$ = this.overviewFacade.downloadsFilterState$;

  private destroy$ = new EventEmitter();

  ngOnInit(): void {
    // use form events to update state
    this.filterForm.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(250))
      .subscribe(
        ({ filter, selectedCadence, selectedVersion, selectedAssetType }) => {
          if (selectedCadence) {
            this.overviewFacade.changeDownloadsCadence(selectedCadence);
          }
          if (selectedVersion) {
            this.overviewFacade.changeDownloadsVersion(selectedVersion);
          }
          if (selectedAssetType) {
            this.overviewFacade.changeDownloadsAssetType(selectedAssetType);
          }
          this.overviewFacade.changeDownloadsFilter(filter ?? null);
        }
      );

    // update the form to reflect current state, but don't trigger form events
    this.filterState$.subscribe(
      ({ filter, selectedCadence, selectedVersion, selectedAssetType }) => {
        this.filterForm.setValue(
          { filter, selectedCadence, selectedVersion, selectedAssetType },
          { emitEvent: false }
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
  }

  clearFilters() {
    this.overviewFacade.clearDownloadsFilters();
    this.filterForm.markAsPristine();
  }
}
