import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'mysas-loading-error',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './loading-error.component.html',
  styleUrls: ['./loading-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingErrorComponent {
  @Input() header = 'Unable to load data';
  @Input() error: string | undefined;
}
