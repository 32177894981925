/* eslint-disable no-restricted-syntax */
import { InlineLoader, Translation } from '@ngneat/transloco';
import { GetLangParams } from '@ngneat/transloco-persist-lang';
import * as deepmerge from 'deepmerge';
import { i18nConfig, l10nConfig } from './i18n.config';
import { ILocale } from './locale.interfaces';

/**
 * using customized getLangFn to ensure that ONLY supported languages are returned
 */
export function getLangFn(langs: GetLangParams): string {
  const cachedLang = langs.cachedLang;
  const browserLang = langs.browserLang;
  const cultureLang = langs.cultureLang;
  const defaultLang = langs.defaultLang;
  // const prefix = `getLangFn(ca=${cachedLang}, br=${browserLang}, cu=${cultureLang}, de=${defaultLang})`;
  let lang = cachedLang || cultureLang || browserLang || defaultLang;
  const locale = langIdToLocale(lang);
  lang = localeToSupportedLangId(locale);

  // console.log(`${prefix} returning ${lang}`);
  return lang;
}

/** returns the locale for a given language id or the default locale */
export const langIdToLocale = (langId: string): string => {
  let userLocale = '';
  if (l10nConfig.langToLocaleMapping !== undefined) {
    userLocale = l10nConfig.langToLocaleMapping[langId];
  } else if (l10nConfig.defaultLocale !== undefined) {
    userLocale = l10nConfig.defaultLocale;
  }
  return userLocale || l10nConfig.defaultLocale || '';
};

export const localeToSupportedLangId = (locale: string): string => {
  const parts = locale.split('-');

  // xx => xx
  if (parts.length == 1) {
    return parts[0];
  }

  if (parts.length > 1) {
    if (isScript(parts[1])) {
      // xx-SS or xx-SS-yy => xx-SS
      return parts[0] + '-' + parts[1];
    } else {
      // xx-yy => xx
      return parts[0];
    }
  }
  // this will never be reached but required for ts lint :)
  /* istanbul ignore next */
  return parts[0];
};

/** scripts to expect */
const SCRIPT_IDS = ['Hans', 'Hant', 'Cyrl', 'Latn', 'Arab'];

/**
 * A script identifier must be in the SCRIPT_IDS list above and be four characters long
 * @param token script identifier to evaluate
 * @returns true if token is a script identifier
 */
export const isScript = (token: string): boolean => {
  return token.length == 4 && SCRIPT_IDS.filter((s) => s == token).length > 0;
};
/**
 * This is probably overkill, but I'm going to leave it in for now until we get concrete
 * examples of what the locale will be.
 * @param locale locale identifier that will include the language and may include country and/or script
 * @returns Locale object with language, country, and script
 */
export const parseLocale = (locale: string): ILocale => {
  // with "-$Script"
  const parts = locale.split(/[-_]/);
  const l: ILocale = { language: parts[0] };
  for (let i = 1; i < parts.length; i++) {
    const token = parts[i];
    if (isScript(token)) {
      l.script = token;
    } else {
      l.country = token;
    }
  }
  return l;
};
/*
From translation team email:

1) It loads the base properties foo.properties. (English)
2) Handle Chinese locales:
If locale is in "zh","zh-Hans","zh_CN","zh_SG", then load foo_zh-Hans if it exists.  If not, load foo_zh instead.
If locale is in "zh-Hant", "zh_TW", "zh_HK", "zh_MO", then load foo_zh-Hant if it exists.  Do not load foo_zh.
   Traditional Chinese locales should not fall back to Simplified Chinese(zh) locale.
2) Handle non-Chinese locales:
search for foo_xx.properties and replace properties with the one from foo_xx if it exists
3) search for foo_xx_yy.propertes and replace properties with the one from foo_xx_yyy if it exists.

*/

/**
 * @param localeId the locale for which filenames should be returned. The language
 * identifier is always first, followed by the country and/or script. The country
 * is joined with an _ and the script with a -. I don't know if both country and
 * script could be in the same localeId. For example, zh_CN is language=chinese,
 * country=China. zh-Hans is language=chinese, script=Hans.
 * @returns List of filenames in ascending order of priority.
 */
export const getLanguageFileNames = (localeId: string): string[] => {
  // const newLocale = getBrowserCultureLang();
  // console.debug(`[locale]: localeId=${localeId}, newLocale=${newLocale}`);
  // localeId = newLocale;
  const prefix = `[locale]: getLanguageFileNames(${localeId}):`;
  const locale = parseLocale(langIdToLocale(localeId)); // getUserLocale();
  const country = locale.country != undefined ? locale.country : '';
  const language = locale.language != undefined ? locale.language : /* istanbul ignore next */ '';
  const script = locale.script != undefined ? locale.script : '';
  const langProps = `assets_base_${language}.properties`;
  const langCountryProps = `assets_base_${language}_${country}.properties`;
  const simplifiedChineseProps = `assets_base_zh-Hans.properties`;
  const traditionalChineseProps = `assets_base_zh-Hant.properties`;
  const baseProps = `assets_base.properties`;
  const files = [];

  // console.debug(prefix, locale);
  // English is a special case because the base properties are equivalent to assets_base_en.properties,
  // but that file will never exist. It is possible to have country-specific files, such as
  // assets_base_en-UK.properties. The base properties are loaded first, then the country properties.
  if (language == 'en') {
    files.push(baseProps);
    if (country != '') {
      files.push(langCountryProps);
    }
    return files;
  }

  // These rules were given to us by the translations team.
  if (language == 'zh') {
    if (script == 'Hans' || country == 'CN' || country == 'SG' || (!script && !country)) {
      // Only the first one is required, but we can't know if it exists or not, so
      // we return both. Since we don't overwrite, the second one will be essentially
      // ignored
      files.push(langProps, simplifiedChineseProps);
      return files;
    } else if (
      script == 'Hant' ||
      country == 'TW' ||
      country == 'HK' ||
      country == 'MO'
    ) {
      files.push(traditionalChineseProps);
      return files;
    }
  }

  // The fallthrough case is to load the language file, the country file if the
  // country is defined, and finally the base properties.
  files.push(baseProps);
  if (country != '') {
    files.push(langCountryProps);
  }
  files.push(langProps);
  return files;
};

export const combinePromises = (
  langPromises: Promise<Translation | undefined>[]
): Promise<Translation> => {
  return Promise.all(langPromises).then((res) => {
    // console.debug('[locale]: promise results to be combined: ', res);
    let baseModule: Translation | undefined;
    for (let i = 0; i < res.length; i++) {
      const langModule = res[i];
      if (langModule == undefined) {
        // console.debug(`[locale]: module ${i} - skipping bad import`);
        continue;
      }

      // find the first non-null result to use as the baseModule
      if (baseModule == undefined) {
        // console.debug(`[locale]: baseModule is #${i}`);
        baseModule = langModule;
        continue;
      }
      // console.log(`[locale]: merging `, baseModule);
      // console.log(`[locale]: with `, langModule);
      try {
        // scoped modules give you modules to combine
        baseModule = {
          default: deepmerge.all([
            baseModule['default'],
            langModule['default'],
          ]),
        };
      } catch {
        // but the http loader gives you plain objects
        baseModule = deepmerge.all([baseModule, langModule]);
      }
    }

    // TODO Handle case when baseModule is undefined?
    return baseModule as Translation;
  });
};

export type ImporterFunc = (lang: string, root: string) => Promise<Translation>;

export const scopeLoader = (
  importer: ImporterFunc,
  root = 'i18n'
): InlineLoader => {
  // WARNING - availableLangs can be a list of strings of LanguageDefinitions,
  // so it's possible this breaks if available langs is anything but a list
  // of strings.
  // ref: https://github.com/ngneat/transloco/blob/master/libs/transloco/src/lib/types.ts
  let langs: string[] = [];
  // const availLangs = i18nConfig.availableLangs;
  if (
    i18nConfig.availableLangs !== undefined &&
    i18nConfig.availableLangs.length > 0
  ) {
    langs = i18nConfig.availableLangs.map((i) =>
      typeof i === 'string' ? i : i.id
    );
  }

  return (langs as string[]).reduce((acc: InlineLoader, lang: string) => {
    acc[lang] = () => importer(lang, root);
    return acc;
  }, {});
};
