import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@mysas/portal/shared/data-access-dialog';

@Component({
  selector: 'mysas-unable-to-download',
  templateUrl: './unable-to-download.component.html',
  styleUrls: ['./unable-to-download.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnableToDownloadComponent {
  constructor(private dialogRef: DialogRef) {}

  close() {
    this.dialogRef.close();
  }
}
