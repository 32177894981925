import { IAppVersion } from './environment.interface';
import { Version } from './version';

export function loadVersion(): IAppVersion {
  return {
    tag: Version.tag,
    shortSHA: Version.shortSHA,
    SHA: Version.SHA,
    buildTime: Version.buildTime,
  } as IAppVersion;
}
