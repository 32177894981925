<ng-container *transloco="let t; read: 'orderOverview'">
  <div class="row" style="margin-block-start: var(--space-xx-large)">
    <div class="col" style="border-right: 1px solid var(--color-ui-border)">
      <dl class="row">
        <dt mysasUiDevIdentifyI18n class="col-3">
          {{ t('orderBasicInfoSiteName.label') }}
        </dt>
        <dd class="col-5">{{ order?.siteName }}</dd>

        <dt mysasUiDevIdentifyI18n class="col-3" style="margin-block-end: var(--space-medium)">
          {{ t('orderBasicInfoTechSupportSite.label') }}
        </dt>
        <dd class="col-5">{{ order?.siteNum }}</dd>

        <dt mysasUiDevIdentifyI18n class="col-3">
          {{ t('orderBasicInfoOfferings.label') }}
        </dt>
        <dd class="col-5">
          <div *ngIf="order !== undefined && order.offerings !== undefined">
            <div *ngIf="order.offerings.length === 1">
              {{ order.offerings[0] }}
            </div>
            <ul *ngIf="order.offerings.length > 1" class="list">
              <li *ngFor="let name of order?.offerings">
                {{ name }}
              </li>
            </ul>
          </div>
        </dd>

        <dt mysasUiDevIdentifyI18n class="col-3">
          {{ t('orderBasicInfoOrderDate.label') }}
        </dt>
        <dd class="col-5">
          {{ order?.date ?? '' | translocoDate: { dateStyle: 'short' } }}
        </dd>

        <dt mysasUiDevIdentifyI18n class="col-3">
          {{ t('orderBasicInfoExpireDate.label') }}
        </dt>
        <dd class="col-5">
          {{ order?.expireDate ?? '' | translocoDate: { dateStyle: 'short' } }}
        </dd>
      </dl>
    </div>

    <div class="col" style="padding-inline-start: var(--space-xx-large)">
      <h2 mysasUiDevIdentifyI18n>{{ t('orderBasicInfoManageOrder.label') }}</h2>

      <ul class="list manage-links">
        <li tabindex="0" class="manage-links__item">
          <a [href]="createCaseLink" target="_blank"
            title="{{ t('orderBasicInfoOpenExternal.label') }}" rel="external" class="manage-links__link">
            <svg localeOverrides="{}" xmlns="http://www.w3.org/2000/svg" class="PopOutPanelArrow manage-links__icon"
              width="14" height="14" version="1.1" viewBox="0 0 14 14" xml:space="preserve">
              <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
              <path class="iconColor"
                d="M11,3.75v5.5C11,9.664,10.664,10,10.25,10S9.5,9.664,9.5,9.25V5.212c-2.37,1.309-3.685,3-4.008,5.149 C5.436,10.733,5.116,11,4.751,11c-0.037,0-0.075-0.003-0.112-0.009c-0.41-0.062-0.692-0.443-0.63-0.853 C4.357,7.82,5.611,5.963,7.8,4.5H4.75C4.336,4.5,4,4.164,4,3.75S4.336,3,4.75,3h5.5C10.664,3,11,3.336,11,3.75z">
              </path>
            </svg>
            <span mysasUiDevIdentifyI18n>{{ t('orderBasicInfoCreateNewCase.label') }}</span>
          </a>
        </li>
        <li tabindex="0" class="manage-links__item">
          <a mysasUiDevIdentifyI18n title="{{ t('orderBasicInfoOpenExternal.label') }}"
            href="https://support.sas.com/sastools/lerequest" target="_blank" class="manage-links__link">
            <svg localeOverrides="{}" xmlns="http://www.w3.org/2000/svg" class="PopOutPanelArrow manage-links__icon"
              width="14" height="14" version="1.1" viewBox="0 0 14 14" xml:space="preserve">
              <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
              <path class="iconColor"
                d="M11,3.75v5.5C11,9.664,10.664,10,10.25,10S9.5,9.664,9.5,9.25V5.212c-2.37,1.309-3.685,3-4.008,5.149 C5.436,10.733,5.116,11,4.751,11c-0.037,0-0.075-0.003-0.112-0.009c-0.41-0.062-0.692-0.443-0.63-0.853 C4.357,7.82,5.611,5.963,7.8,4.5H4.75C4.336,4.5,4,4.164,4,3.75S4.336,3,4.75,3h5.5C10.664,3,11,3.336,11,3.75z">
              </path>
            </svg>
            <span mysasUiDevIdentifyI18n>{{ t('orderBasicInfoExtendLic.label') }}</span>
          </a>
        </li>
        <li tabindex="0" class="manage-links__item">
          <a mysasUiDevIdentifyI18n title="{{ t('orderBasicInfoOpenExternal.label') }}"
            href="https://support.sas.com/techsup/license/index.html" target="_blank" class="manage-links__link">
            <svg localeOverrides="{}" xmlns="http://www.w3.org/2000/svg" class="PopOutPanelArrow manage-links__icon"
              width="14" height="14" version="1.1" viewBox="0 0 14 14" xml:space="preserve">
              <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
              <path class="iconColor"
                d="M11,3.75v5.5C11,9.664,10.664,10,10.25,10S9.5,9.664,9.5,9.25V5.212c-2.37,1.309-3.685,3-4.008,5.149 C5.436,10.733,5.116,11,4.751,11c-0.037,0-0.075-0.003-0.112-0.009c-0.41-0.062-0.692-0.443-0.63-0.853 C4.357,7.82,5.611,5.963,7.8,4.5H4.75C4.336,4.5,4,4.164,4,3.75S4.336,3,4.75,3h5.5C10.664,3,11,3.336,11,3.75z">
              </path>
            </svg>
            <span mysasUiDevIdentifyI18n>{{ t('orderBasicInfoRenewLic.label') }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>