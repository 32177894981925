import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { OutageAlertService } from '@mysas/portal/shared/data-access-outages';
import { IAlert, IAlertOption } from '@mysas/shared/data-access-alerts';
import { TranslocoService } from '@ngneat/transloco';
import {
  DateFormatOptions,
  TranslocoLocaleService,
} from '@ngneat/transloco-locale';
import { NGXLogger } from 'ngx-logger';

const dateFormatOpts: DateFormatOptions = {
  timeStyle: 'short',
  dateStyle: 'medium',
};

@Component({
  selector: 'mysas-outage-alert',
  standalone: true,
  imports: [CommonModule],
  providers: [OutageAlertService, TranslocoService, NGXLogger, DatePipe],
  templateUrl: './outage-alert.component.html',
  styleUrls: ['./outage-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutageAlertComponent implements OnInit {
  @Input() alert!: IAlert;
  @Input() options!: IAlertOption[];

  label: string | undefined;

  private _dismissed = false;
  private readonly logger = inject(NGXLogger);

  @HostBinding('class.dismissed')
  get dismissed() {
    return this.alert.level === 'warn' ? this._dismissed : false;
  }
  set dismissed(val: boolean) {
    if (this.alert.level === 'warn') {
      this._dismissed = val;
    }
  }

  private readonly transloco = inject(TranslocoService);
  private readonly localeService = inject(TranslocoLocaleService);
  private readonly cdr = inject(ChangeDetectorRef);

  ngOnInit() {
    this.logger.partialUpdateConfig({
      context: OutageAlertComponent.name,
      enableSourceMaps: false,
      disableFileDetails: true,
    });
    const option = this.options.find((o) => o.id === this.alert.messageId);

    // if for some reason the messageId doesn't line up and a template string can't be found,
    // automatically hide this alert
    if (!option) {
      this.logger.warn(
        `No alert option data available! This alert will be hidden automatically`,
        this.options
      );
      this.label = 'Alert';
      this._dismissed = true;
      this.cdr.detectChanges();
    } else {
      if (!this.alertIsVisible(this.alert)) {
        this.logger.debug(
          `Alert for ${this.alert.ritmId} is not visible until ${
            this.alert.warningStartDate ?? this.alert.startDate
          }`
        );
        this._dismissed = true;
        return;
      }

      const message = option.templates.find(
        (o) => o.alertLevel === this.alert.level
      )?.template;
      if (!message) {
        this.logger.error(
          `No string template found for messageId ${this.alert.messageId}`
        );
        throw new Error(`No string template could be found!`);
      }

      const params: Record<string, string | null> = {};
      if (message.includes('startDateTime')) {
        params['startDateTime'] = this.localizeDate(this.alert.startDate);
      }

      if (message.includes('endDateTime') && this.alert.endDate) {
        params['endDateTime'] = this.localizeDate(this.alert.endDate);
      }

      this.logger.debug(
        `\nMessage ID: ${option.id}.${this.alert.level}\nOriginal string: ${message}`,
        params
      );

      this.transloco
        .selectTranslate(`${option.id}.${this.alert.level}`, params)
        .subscribe({
          next: (val: string) => {
            this.logger.debug(
              `Getting translated template for ${option.id}: ${val}`,
              params
            );
            this.label = val;
            this.cdr.detectChanges();
          },
        });
    }
  }

  dismiss() {
    this.dismissed = true;
  }

  /**
   * The returned endDate in alerts is appended with `UTC` instead of `Z`, which
   * is not recognized by the regex in transloco-locale here:
   * https://github.com/ngneat/transloco/blob/52601277edfe5ef3502a822fc6129e28723bc16c/libs/transloco-locale/src/lib/helpers.ts#L5
   *
   */
  private localizeDate(date: string, opts?: DateFormatOptions): string | null {
    if (!date) {
      return null;
    }
    if (date.includes('UTC')) {
      date = date.replace('UTC', 'Z');
    }
    try {
      return this.localeService.localizeDate(
        date,
        this.localeService.getLocale(),
        { ...dateFormatOpts, ...opts }
      );
    } catch (err) {
      this.logger.error(`Error while localizing date for outage alert`, err);
      throw new Error(`Error while localizing date`);
    }
  }

  /**
   * Ensures that alerts with a warningStartDate are only shown on or after start
   * date.
   * @param alert
   */
  private alertIsVisible(alert: IAlert): boolean {
    const today = new Date().getTime();
    if (alert.warningStartDate !== null) {
      const warningStartDate = Date.parse(alert.warningStartDate);
      return today >= warningStartDate;
    }
    return today >= Date.parse(alert.startDate);
  }
}
