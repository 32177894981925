import { createAction, props } from '@ngrx/store';

import { INotification } from '@mysas/shared/data-access-notifications';

/** Create Notification Actions */

export const addNotification = createAction(
  '[Notifications] Adding Notification',
  props<{ notification: INotification }>()
);

/**
 * Delete Actions
 *
 * NOTE - these actions are not for marking notifications as read, they
 * are meant for actually removing the notifications from storage!
 * */

export const deleteNotification = createAction(
  '[Notifications] Delete Notification',
  props<{ slug: string }>()
);

export const deleteMultipleNotifications = createAction(
  '[Notifications] Delete Many Notifications',
  props<{ slugs: string[] }>()
);

export const deleteAllNotifications = createAction(
  '[Notifications] Delete All Notifications'
);

/** Create Batch of Notifications */

export const batchAddNotifications = createAction(
  '[Notifications] Batch Adding Notifications',
  props<{ notifications: INotification[] }>()
);

/** Hydration-related Actions */

export const hydrateNotificationsFromStorage = createAction(
  '[Notifications/Hydrate] Hydrate'
);

export const hydrateNotificationsSuccess = createAction(
  '[Notifications/Hydrate] Hydrate Success',
  props<{ notifications: INotification[] }>()
);

export const hydrateNotificationsFailure = createAction(
  '[Notifications/Hydrate] Hydrate Failure',
  props<{ error: string }>()
);

/** Dismissal Actions */

export const dismissNotification = createAction(
  '[Notifications] Notification Dismissed',
  props<{ notification: Pick<INotification, 'slug' | 'dismissed'> }>()
);
