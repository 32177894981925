<ng-container *transloco="let t; read: 'orderOverview'">
  <div class="dialog">
    <div class="dialog__content">
      <div class="row">
        <div class="col">
          <h2 class="dialog__title">
            {{ t('unableToDownload.label') }}
          </h2>
        </div>
      </div>

      <div class="row">
        <div
          class="col d-flex align-items-center justify-content-center"
          style="flex: 0"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.854 9.146C10.049 9.341 10.049 9.658 9.854 9.853C9.756 9.951 9.628 10 9.5 10C9.372 10 9.244 9.951 9.146 9.854L7 7.707L4.854 9.854C4.756 9.951 4.628 10 4.5 10C4.372 10 4.244 9.951 4.146 9.854C3.951 9.659 3.951 9.342 4.146 9.147L6.293 7L4.146 4.854C3.951 4.659 3.951 4.342 4.146 4.147C4.341 3.952 4.658 3.952 4.853 4.147L7 6.293L9.146 4.147C9.341 3.952 9.658 3.952 9.853 4.147C10.048 4.342 10.048 4.659 9.853 4.854L7.707 7L9.854 9.146ZM14 7C14 10.866 10.866 14 7 14C3.134 14 0 10.866 0 7C0 3.134 3.134 0 7 0C10.866 0 14 3.134 14 7ZM13 7C13 3.691 10.309 1 7 1C3.691 1 1 3.691 1 7C1 10.309 3.691 13 7 13C10.309 13 13 10.309 13 7Z"
              fill="#D60000"
            />
          </svg>
        </div>

        <div class="col d-flex" style="flex: 1">
          <p class="dialog__text">
            {{ t('unableToDownload.txt') }}
          </p>
        </div>
      </div>
    </div>

    <div class="dialog__footer">
      <div class="row">
        <div class="col d-flex justify-content-end">
          <button tabindex="0" class="btn btn-primary" (click)="close()">
            {{ t('unableToDownloadClose.label') }}
          </button>
        </div>
      </div>
    </div>

    <div class="dialog__dismiss">
      <button
        tabindex="0"
        class="btn btn-standard-no-bd-bg"
        (click)="close()"
      ></button>
    </div>
  </div>
</ng-container>
