<div class="main-container" *ngIf="isLoggedIn$ | async; else loggedOutRouter">
  <ng-container
    *ngIf="{
      alerts: outageAlerts$ | async,
      options: outageAlertOptions$ | async
    } as obs"
  >
    <div class="outage-alert-container" *ngIf="obs.alerts?.length">
      <ng-container *ngFor="let alert of obs.alerts">
        <mysas-outage-alert [alert]="alert" [options]="obs.options ?? []"
          >Alert</mysas-outage-alert
        >
      </ng-container>
    </div>
  </ng-container>
  <banner></banner>
  <div class="content-container">
    <left-nav></left-nav>
    <router-outlet></router-outlet>
  </div>
  <chat></chat>
</div>

<ng-template #loggedOutRouter>
  <div class="main-container" id="logged-out-container">
    <div class="content-container">
      <div class="content-area">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-template>
