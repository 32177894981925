<div class="alert alert--{{ state }}">
  <div class="alert__icon">
    <ng-container [ngSwitch]="state">
      <svg
        *ngSwitchCase="'alert'"
        localeOverrides="{}"
        xmlns="http://www.w3.org/2000/svg"
        class="Alert"
        width="14"
        height="14"
        version="1.1"
        viewBox="0 0 14 14"
        xml:space="preserve"
      >
        <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
        <path
          class="iconColor"
          d="M7,12c-0.631,0-1.143-0.512-1.143-1.143c0-0.631,0.512-1.143,1.143-1.143c0.631,0,1.143,0.512,1.143,1.143 C8.143,11.488,7.631,12,7,12z M7.762,8.571H6.238L5.857,4h2.286L7.762,8.571z M13.76,11.319L8.609,0.917 c-0.706-1.223-2.512-1.223-3.218,0L0.24,11.319C-0.453,12.518,0.437,14,1.849,14h10.302C13.563,14,14.452,12.518,13.76,11.319z M12.894,12.583C12.822,12.708,12.604,13,12.151,13H1.849c-0.453,0-0.671-0.292-0.743-0.417c-0.066-0.115-0.199-0.419,0-0.764 L6.287,1.361C6.474,1.041,6.849,1,7,1c0.151,0,0.526,0.041,0.743,0.417l5.121,10.345C13.093,12.164,12.961,12.468,12.894,12.583z"
        ></path>
      </svg>

      <svg
        *ngSwitchCase="'info'"
        localeOverrides="{}"
        xmlns="http://www.w3.org/2000/svg"
        class="Information"
        width="14"
        height="14"
        version="1.1"
        viewBox="0 0 14 14"
        xml:space="preserve"
      >
        <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
        <path
          class="iconColor"
          d="M7,0C3.134,0,0,3.134,0,7c0,3.866,3.134,7,7,7s7-3.134,7-7C14,3.134,10.866,0,7,0z M7,13 c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S10.309,13,7,13z M7,11c-0.414,0-0.75-0.336-0.75-0.75v-3C6.25,6.836,6.586,6.5,7,6.5 s0.75,0.336,0.75,0.75v3C7.75,10.664,7.414,11,7,11z M6,4c0-0.552,0.448-1,1-1s1,0.448,1,1S7.552,5,7,5S6,4.552,6,4z"
        ></path>
      </svg>
    </ng-container>
  </div>
  <div tabindex="0" class="alert__text">
    <ng-content></ng-content>
  </div>

  <div class="alert__dismissable" *ngIf="dismissable">
    <svg
      localeOverrides="{}"
      xmlns="http://www.w3.org/2000/svg"
      class="Close"
      width="14"
      height="14"
      version="1.1"
      viewBox="0 0 14 14"
      xml:space="preserve"
    >
      <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
      <path
        class="iconColor"
        d="M10.766,9.713c0.292,0.293,0.292,0.769-0.001,1.061c-0.146,0.146-0.338,0.22-0.53,0.22 s-0.384-0.073-0.531-0.22L7.004,8.07l-2.722,2.712C4.135,10.927,3.944,11,3.752,11c-0.192,0-0.385-0.073-0.531-0.221 c-0.292-0.293-0.292-0.769,0.002-1.061l2.721-2.71L3.219,4.28C2.927,3.987,2.927,3.512,3.22,3.22c0.292-0.293,0.768-0.293,1.061,0 l2.726,2.73l2.714-2.703c0.294-0.292,0.769-0.292,1.061,0.002c0.292,0.293,0.292,0.769-0.002,1.061L8.067,7.01L10.766,9.713z"
      ></path>
    </svg>
  </div>
</div>
