<ng-container *transloco="let t">
  <div class="error-container">
    <div class="icon">
      <svg
        localeOverrides="{}"
        xmlns="http://www.w3.org/2000/svg"
        class="ErrorStatus"
        width="14"
        height="14"
        version="1.1"
        viewBox="0 0 14 14"
        xml:space="preserve"
      >
        <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
        <path
          class="alert"
          fill="#D80000"
          d="M9.854,9.146c0.195,0.195,0.195,0.512,0,0.707C9.756,9.951,9.628,10,9.5,10 S9.244,9.951,9.146,9.854L7,7.707L4.854,9.854C4.756,9.951,4.628,10,4.5,10S4.244,9.951,4.146,9.854 c-0.195-0.195-0.195-0.512,0-0.707L6.293,7L4.146,4.854c-0.195-0.195-0.195-0.512,0-0.707c0.195-0.195,0.512-0.195,0.707,0L7,6.293 l2.146-2.146c0.195-0.195,0.512-0.195,0.707,0c0.195,0.195,0.195,0.512,0,0.707L7.707,7L9.854,9.146z M14,7c0,3.866-3.134,7-7,7 s-7-3.134-7-7s3.134-7,7-7S14,3.134,14,7z M13,7c0-3.309-2.691-6-6-6S1,3.691,1,7s2.691,6,6,6S13,10.309,13,7z"
        ></path>
      </svg>
    </div>
    <div class="header">
      <h2 tabindex="0">{{ header }}</h2>
    </div>
    <div class="message">
      <p tabindex="0" *ngIf="error">
        {{ error }}
      </p>
      <p tabindex="0" *ngIf="!error">
        {{ t('loadingError.txt') }}
      </p>
    </div>
  </div>
</ng-container>
