import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TokenComponentModule } from '@mysas/portal/shared/ui-layout';
import { HeroTileComponent } from './hero-tile/hero-tile.component';

@NgModule({
  imports: [CommonModule, TokenComponentModule],
  declarations: [HeroTileComponent],
  exports: [HeroTileComponent],
})
export class UiModule {}
