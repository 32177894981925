import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  preferencesAdapter,
  PreferencesState,
  PREFERENCES_FEATURE_KEY,
} from './preferences.reducer';

// Lookup the 'Preferences' feature state managed by NgRx
export const getPreferencesState = createFeatureSelector<PreferencesState>(
  PREFERENCES_FEATURE_KEY
);

const { selectAll, selectEntities } = preferencesAdapter.getSelectors();

export const getPreferencesLoaded = createSelector(
  getPreferencesState,
  (state: PreferencesState) => state.loaded
);

export const getPreferencesError = createSelector(
  getPreferencesState,
  (state: PreferencesState) => state.error
);

export const getAllPreferences = createSelector(
  getPreferencesState,
  (state: PreferencesState) => selectAll(state)
);

export const getPreferencesEntities = createSelector(
  getPreferencesState,
  (state: PreferencesState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getPreferencesState,
  (state: PreferencesState) => state.selectedId
);

export const getSelected = createSelector(
  getPreferencesEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getSubscribedToVersionUpdate = createSelector(
  getAllPreferences,
  (prefs) => prefs.find((p) => p.id === 'newVersion')?.subscribed_bool ?? false
);
