import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BannerService } from '@mysas/portal/shared/ui-layout';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'mysas-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  constructor(
    private bannerService: BannerService,
    private transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.transloco
      .selectTranslate('manageDeploySas.label')
      .subscribe((val) => this.bannerService.updateBannerText(val));
  }
}
