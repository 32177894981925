import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';

import { ComponentType } from '@angular/cdk/portal';
import {
  IOrdersRequestAccess,
  SortByType,
  SortDirectionType,
  sortOrders,
} from '@mysas/shared/data-access-orders';
import { NGXLogger } from 'ngx-logger';
import * as OrdersActions from './orders.actions';
import * as OrdersSelectors from './orders.selectors';

@Injectable({
  providedIn: 'root',
})
export class OrdersFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.select(OrdersSelectors.getOrdersLoaded);
  allOrders$ = this.store.select(OrdersSelectors.getAllOrders);
  selectedOrders$ = this.store.select(OrdersSelectors.getSelected);
  sortBy$ = this.store.select(
    OrdersSelectors.getOrdersSortBy
    // distinctUntilChanged()
  );
  sortDirection$ = this.store.select(
    OrdersSelectors.getOrdersSortDirection
    // distinctUntilChanged()
  );
  filter$ = this.store.select(OrdersSelectors.getOrdersFilter);

  sortedAndFilteredOrders$ = combineLatest([
    this.allOrders$,
    this.sortBy$,
    this.sortDirection$,
    this.filter$,
  ]).pipe(
    // tap(([, sortBy, sortDirection, filter]) =>
    //   this.logger.debug(JSON.stringify({ sortBy, sortDirection, filter }, null, 2))
    // ),
    map(([orders, sortBy, sortDirection, filter]) =>
      orders
        .sort((a, b) => sortOrders(a, b, sortBy, sortDirection))
        .filter((order) =>
          filter
            ? order.number.toLowerCase().includes(filter.toLowerCase()) ||
              order.siteName.toLowerCase().includes(filter.toLowerCase()) ||
              order.siteNum.toLowerCase().includes(filter.toLowerCase())
            : true
        )
    )
  );

  accessRequest$ = this.store.select(OrdersSelectors.getAccessRequestState);

  logger = inject(NGXLogger);

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.logger.debug(`[OrdersFacade] Dispatching action to load orders`);
    this.store.dispatch(OrdersActions.initOrders());
  }

  changeSortBy(sortBy: SortByType) {
    this.store.dispatch(OrdersActions.changeSortBy({ sortBy }));
  }

  changeSortDirection(sortDirection: SortDirectionType) {
    this.store.dispatch(OrdersActions.changeSortDirection({ sortDirection }));
  }

  toggleSortDirection() {
    this.store.dispatch(OrdersActions.toggleSortDirection());
  }

  changeFilter(filter: string | null) {
    this.store.dispatch(OrdersActions.changeFilter({ filter }));
  }

  openAccessRequestDialog(component: ComponentType<unknown>) {
    this.store.dispatch(OrdersActions.openAccessRequestDialog({ component }));
  }

  requestAccess(data: IOrdersRequestAccess) {
    this.store.dispatch(
      OrdersActions.submitAccessRequest({ payload: { ...data } })
    );
  }
}
