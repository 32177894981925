import { CommonModule } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgModule,
  Renderer2,
} from '@angular/core';
import { environment } from '@mysas/shared/util-environment';

declare global {
  interface Window {
    debugi18n: unknown;
  }
}

@Directive({
  selector: '[mysasUiDevIdentifyI18n]',
})
export class DevIdentifyI18nDirective {
  /**
   * Use binding to set a color besides red. This may be helpful for troubleshooting
   * a single component. The default is red, but you may bind the current component
   * color to blue to make the current component's strings stand out.
   */
  @Input() color = '#ff0000';
  isActivated = false;

  debugParam = 'debugi18n';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  /**
   * Always inactive in production, and turned on from devtools by setting window property otherwise
   */
  isActive() {
    if (environment.production) {
      return false;
    }
    if (environment.debugi18n) {
      return true;
    }
    return window.debugi18n == '1';
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.isActive()) {
      return;
    }
    const isHexCode = new RegExp('^#[0-9a-f]{1,6}$', 'i');
    const mightBeNamedColor = new RegExp('^[a-z]{3,15}$');

    if (!isHexCode.test(this.color) && !mightBeNamedColor.test(this.color)) {
      console.error(
        `an invalid color for mysasUiDevIdentifyI18n was given: ${this.color}`
      );
      return;
    }

    this.renderer.setStyle(
      this.el.nativeElement,
      'border',
      `1px solid ${this.color}`
    );
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (!this.isActive()) {
      return;
    }
    this.renderer.removeStyle(this.el.nativeElement, 'border');
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [DevIdentifyI18nDirective],
  exports: [DevIdentifyI18nDirective],
})
export class DevIdentifyI18nDirectiveModule {}
