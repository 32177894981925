import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabBodyComponent } from './tab-body.component';
import { TabGroupComponent } from './tab-group.component';
import { TabLabelComponent } from './tab-label.component';
import { TabComponent } from './tab.component';

@NgModule({
  declarations: [
    TabComponent,
    TabGroupComponent,
    TabLabelComponent,
    TabBodyComponent,
  ],
  imports: [CommonModule],
  exports: [
    TabLabelComponent,
    TabBodyComponent,
    TabComponent,
    TabGroupComponent,
  ],
})
export class TabsModule {}
