import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OktaAuthStateService } from '@okta/okta-angular';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { OrdersService } from '../services/orders.service';

import * as PreferencesActions from './preferences.actions';

@Injectable()
export class PreferencesEffects {
  triggerUserPrefs$ = createEffect(() => {
    return this.authStateService.authState$.pipe(
      filter(({ isAuthenticated }) => isAuthenticated === true),
      map(() => PreferencesActions.LoadPreferences.load())
    );
  });

  loadUserPrefs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferencesActions.LoadPreferences.load),
      switchMap(() =>
        this.ordersService.getUserPrefs().pipe(
          map((preferences) =>
            PreferencesActions.LoadPreferences.success({ preferences })
          ),
          catchError((err: Error) => {
            return of(
              PreferencesActions.LoadPreferences.failure({ error: err.message })
            );
          })
        )
      )
    );
  });

  updatePreference$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PreferencesActions.UpdatePreferences.update),
      switchMap(({ preference }) =>
        this.ordersService.updateUserPreference(preference).pipe(
          map((preference) =>
            PreferencesActions.UpdatePreferences.success({ preference })
          ),
          catchError((error: Error) =>
            of(
              PreferencesActions.UpdatePreferences.failure({
                error: error.message,
              })
            )
          )
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private ordersService: OrdersService,
    private authStateService: OktaAuthStateService
  ) {}
}
