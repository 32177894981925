<ng-container
  *ngIf="{
    cadenceDropdownOptions: cadenceDropdownOptions$ | async,
    versionDropdownOptions: versionDropdownOptions$ | async,
    assetTypeDropdownOptions: assetTypeDropdownOptions$ | async
  } as obs"
>
  <div class="row downloads-toolbar" *transloco="let t; read: 'orderOverview'">
    <div class="col form-group-row" [formGroup]="filterForm">
      <div class="form-group">
        <input
          tabindex="0"
          class="filter-input"
          type="text"
          placeholder="{{ t('filterPlaceholder.label') }}"
          formControlName="filter"
          attr.aria-label="{{ t('filterPlaceholder.aria') }}"
        />
      </div>

      <div class="form-group">
        <select
          tabindex="0"
          class="form-control-sm cadence-control"
          formControlName="selectedCadence"
          attr.aria-label="{{ t('filterByOrderType.aria') }}"
        >
          <option [value]="null" disabled>
            {{ t('downloadsToolbarDropdownCadence.label') }}
          </option>
          <option *ngFor="let opt of obs.cadenceDropdownOptions" [value]="opt">
            {{ opt }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <select
          tabindex="0"
          class="form-control-sm version-control"
          formControlName="selectedVersion"
          attr.aria-label="{{ t('filterByVersion.aria') }}"
        >
          <option [value]="null" disabled>
            {{ t('downloadsToolbarDropdownVersion.label') }}
          </option>
          <option *ngFor="let opt of obs.versionDropdownOptions" [value]="opt">
            {{ opt }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <select
          tabindex="0"
          class="form-control-sm asset-control"
          formControlName="selectedAssetType"
          attr.aria-label="{{ t('filterByAssetType.aria') }}"
        >
          <option [value]="null" disabled>
            {{ t('downloadsToolbarDropdownAssetType.label') }}
          </option>
          <option
            *ngFor="let opt of obs.assetTypeDropdownOptions"
            [value]="opt"
          >
            {{ opt | assetType }}
          </option>
        </select>
      </div>

      <button
        tabindex="0"
        class="btn btn-primary"
        *ngIf="filterForm.dirty"
        (click)="clearFilters()"
      >
        {{ t('filterClear.label') }}
      </button>
    </div>
  </div>
</ng-container>
